import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import HeroItDepartment from '../components/HeroItDepartment'
import CustomerCarousel from '../components/CustomerCarousel'
import ItServices from '../components/ItServices'
import { pageContact } from '../common/data'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class ItDepartment extends Component {
    render() {
        const { roadmap, pdf_file } = pageContact
        return (
            <div id="page-header" className='services-page'>
                <LazyLoad height={200} once >
                <HeroItDepartment 
                    title="IT DEPARTMENT"
                    header='IT Management by the numbers'
                    data1="53"
                    data2="87"
                    data3="832.1"
                    p1="reduction in employee errors by integrating workflows with technology."
                    p2="of companies feel that a strategic IT partnership gives their business a competitive edge."
                    p3="is the expected market size for IT services in 2023."
                />
                </LazyLoad>
                <LazyLoad height={200} once >
                <ItServices />
                </LazyLoad>
                <VideoSection
                    title='CorpTech Helps Vanguard Electronics Achieve Their Goals Despite COVID-19'
                    poster='/videos/posters/manufacturing_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/manufacturing_industry_vanguard_electronics_case_study.mp4'
                />
                <div id="it-customercard">
                    <CustomerCarousel />
                </div>
                <div className="page-contact">
                    <div className="page-contact--img" data-aos-anchor-placement="center-bottom" data-aos="fade-right" />
                    <div data-aos-anchor-placement="center-bottom" data-aos="fade-left" className="page-contact--actions">
                    <Link to="/contact-us" className={`action--contact ${roadmap}`} />
                        <a href={`${pdf_file}`} className='action--download' rel="noopener noreferrer" target='_blank' download>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
