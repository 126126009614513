/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import { pages } from './data.js'
import {NavLink} from 'react-router-dom'
import Banner from './Banner.js'

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      activeTab: ''
    }
  }
  gotoTop = () => {
    window.scroll(0,0)
  }

  

  componentDidMount() {
    const btnTop = document.getElementById("to_top_btn");
    const navId = document.getElementById('nav');
    function scrollFunction() {
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        navId.style.padding = "0px 40px";
        navId.style.boxShadow = "0px 2px 8px #c9c7c7";
        navId.style.backgroundColor = '#fff'
        document.getElementById("img-logo").style.width = "230px";
        document.getElementById("img-logo").style.padding = ".5rem";
        btnTop.style.display="block";

      } else {
        navId.style.backgroundColor = 'transparent'
        navId.style.padding = "15px 40px";
        navId.style.boxShadow = "none";
        document.getElementById('nav-links').style.top = "0";
        document.getElementById("img-logo").style.width = "270px";
        btnTop.style.display = "none";
  
      }
    }


    window.onscroll =  function() {scrollFunction()}
    window.onload = function() {scrollFunction()};
    // window.onscroll = function() {onScrollTop()};

    const current = window.location.href;
    const menuItem = document.querySelectorAll('.nav-drawer__ul a')
    const menuLength = menuItem.length;
    // const dropdownLength = dropdownItem.length;
    for (let i = 0; i<menuLength; i++){
      if(menuItem[i].href === current){
        menuItem[i].className = 'active'
      }
    }
    
    
    this.changeOpacity()
  }

  componentDidUpdate(_) {
    this.changeOpacity()
  }
  
  changeOpacity() {
    if (this.props.location.pathname === '/') {
      document.getElementById('img-logo').style.opacity = '0';
      document.getElementById('img-logo').style.cursor = 'default';
      console.log(pages)
    } else {
      document.getElementById('img-logo').style.opacity = '1';
      document.getElementById('img-logo').style.cursor = 'pointer';
      console.log('else ni')
    }
  }
  navShow = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-drawer__ul');

      document.getElementById('nav-drawer').classList.toggle('show-nav')
      nav.classList.toggle('nav-active');
      burger.classList.toggle('toggle');
  }
  showIndMenu = () => {
    const indMenu = document.getElementById('indMenu');
    const arrowIcon = document.getElementById('arrow1')
    indMenu.classList.toggle('show-nav')
    arrowIcon.classList.toggle('rotate')
    
  }
  showServMenu = () => {
    const servMenu = document.getElementById('servMenu');
    const arrowIcon = document.getElementById('arrow2')
    servMenu.classList.toggle('show-nav')
    arrowIcon.classList.toggle('rotate')
  }
  render() {
    console.log(this.props)
    const { activeTab } = this.state
    console.log({activeTab}, 'hello')
    const pageRoutes = pages.map(d => <NavLink className="nav__link--list" to={d.path} onClick={() => this.setState({ activeTab: d.parent })}>{d.name}</NavLink>)
    const industriesPage = pageRoutes.splice(1, 9);
    const secondMenu = pages.map(d => <NavLink className="nav__link--list" to={d.path} onClick={
      () => this.setState({ activeTab: d.parent })}>{d.name}</NavLink>)
    const servicesPage = secondMenu.slice(12, 15);
    const industriesMenu = pages.map(d => <a className="nav__link--list" href={d.path}>{d.name}</a>)
    const industriesLink = industriesMenu.splice(1, 9);
    const servicesMenu = pages.map(d => <a onClick={this.activeBurger} className="nav__link--list" href={d.path}>{d.name}</a>)
    const servicesLink = servicesMenu.slice(12, 15);


    return (
      <div className='sticky'>
        {/* NOTE: Remove margin-top when removing banner find .headernobanner and comment styles*/}
        {/* <Banner/>  */}
        <nav id="nav">
          <div className="logo" id="logo">
            <a href="/"><img id="img-logo" src="/imgs/btmp-logo.png" alt="logo"/></a>
          </div>
          <div className="f-center">
          <ul id="nav-links" className="nav__ul nav-links">
              <li id="industries-tab" className={`nav__link dropdown ${activeTab === 'Industries' ? 'active' : ''}`}>
                <a>Industries</a>
                <div className="dropdown-content">
                  {industriesPage}
                </div>
              </li>
              <li id="services-tab" className={`nav__link dropdown ${activeTab === 'Services' ? 'active' : ''}`}>
                <a>Services</a>
                <div className="dropdown-content">
                  {servicesPage}
                </div>
              </li>
              <NavLink onClick={() => this.setState({ activeTab: pages.parent })}
              className={`nav__link--list ${activeTab === 'Meet the Team' ? 'active' : ''}`} to="/about-us">
              <li>Meet the Team</li>
              </NavLink>
              <NavLink onClick={() => this.setState({ activeTab: pages.parent })}
              className={`nav__link--list ${activeTab === 'Terms of Service' ? 'active' : ''}`} to="/terms-of-service">
              <li>Terms of Service</li>
              </NavLink>
              <NavLink onClick={() => this.setState({ activeTab: pages.parent })}
              className={`nav__link--list ${activeTab === 'Contact Us' ? 'active' : ''}`} to="/contact-us">
              <li>Contact Us</li>
              </NavLink>
              <li className="nav__link-btn">
                <a href="https://corporatetech.sharepoint.com/" id="login">Log In</a>
              </li>
          </ul>
          <aside id="nav-drawer">
            <div id="aside-nav-links" className="nav-drawer__ul nav-links">
                <li className="logo-2">
                  <a href="/"><img src="/imgs/btmp-logo.png" alt="logo"/></a>
                </li>
                <li className="nav-drawer__link dropdown animate__flipInX" onClick={this.showIndMenu}>
                    <div className="label-align">
                    <a id="industries-tab">Industries</a><i id="arrow1" className="arrow down-arrow"></i>
                    </div>
                    <div id="indMenu" className="dropdown-content">
                      {industriesLink}
                    </div>
                </li>
                <li className="nav-drawer__link dropdown" onClick={this.showServMenu}>
                  <div className="label-align">
                    <a id="services-tab">Services</a><i id="arrow2" className="arrow down-arrow"></i>
                  </div>
                  
                  <div id="servMenu" className="dropdown-content">
                    {servicesLink}
                  </div>
                </li>
                <li className="nav-drawer__link--list">
                  <a href="/about-us" >
                    Meet the Team
                  </a>
                </li>
                <li onClick={() => this.setState({ activeTab: pages.parent })} className="nav-drawer__link--list">
                  <a href="/terms-of-service" >
                  Terms of Service
                  </a>
                </li>
                <li className="nav-drawer__link--list">
                  <a href="/contact-us" >
                  Contact Us
                  </a>
                </li>
                
                <li className="nav-drawer__link-btn">
                  <a href="https://login.live.com/login.srf" id="login">Log In</a>
                </li>
            </div>
          </aside>
          </div>
            <div className="burger" onClick={this.navShow}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
          </div>
        </nav>
        <div className="back_to_top" id="to_top_btn" data-aos="fade-up" onClick={this.gotoTop}>
            <i className='dnaicon dnaicon-chevron-up'/>
        </div>
      </div>
    )
  }
}


export default Header