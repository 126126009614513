import React, { Component } from 'react'



export default class WH_ServiceLevel extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Service Level Guarantees</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Service Level Guarantees</h1>
                    <h1 className="service__title--center">30 DAY MONEY BACK GUARANTEE</h1>
                    <p className="terms__description--padding-small">If, for any reason, you are dissatisfied with our hosting services, we will refund your hosting fees at any time during the first 30 days after you have signed up for an account with us. You can email us at billing@corporatetech.com and receive a full refund for your hosting plan within the 30-day money-back period.</p>
                    <p className="terms__description--padding-small">Please, NOTE that domain name registrations are final and non-refundable. As changes are no longer allowed after a registration request is submitted, you should always check for correct spelling before purchasing a domain name.</p>
                    <div className="container-divider">
                        <ul> 
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>SERVICE GUARANTEES</dt>
                                <dd className="sub_detail_title">99.9% Network Uptime Guarantee</dd>
                                <dd>Corporate Technology guarantees that your website(s) will be up and running at least 99.9% of the time during any 12-month period. This guarantee includes network and server uptime. However, we cannot be held responsible for upstream problems outside of our network and areas where Corporate Technology has no direct influence, such as backbone provider failures, fiber-optic main line cuts, DNS or Registrar problems with subscribers’ domain names, routing problems between a client’s location and our data center. The uptime guarantee is also not applicable if the service interruption was caused by a natural or unnatural event that is not directly under the control (or jurisdiction) of Corporate Technology. Corporate Technology may temporarily deny or terminate the Service upon client’s failure to pay their charges at due time.</dd>
                                <dd>Corporate Technology guarantees 99.9% network uptime and server stability. This, however, does not refer to problems stemming from:</dd>
                                    <ol className="abcd_ordered_list" type="a">
                                        <li>Server Hardware Breakdown.</li>
                                        <li>User error(s) or purposeful interruption(s) (e.g. if the client shuts his/her own server down, Corporate Technology is not responsible for the downtime).</li>
                                        <li>Failures due to software that is not explicitly supported by Corporate Technology. Consequently, if a hardware crash provoked by the customer happens, Corporate Technology has no responsibility for the resulting downtime.</li>
                                    </ol>

                                <dd className='sub_detail_title'>Network Outages or Unexpected Downtime <span>is any unplanned or unexpected interruption in the network availability due to hardware, software, network connectivity or data center problem, during which a 100% packet loss is experienced.</span></dd>
                                <dd className='sub_detail_title'>Scheduled Downtime <span>is any scheduled interruption of the services for the purpose of network software/hardware upgrades, or replacement of any network equipment in order to be provided better service for the customers. Scheduled downtimes occur within pre-notified downtime periods, with as many warnings as possible sent via e-mail or posted in our news section minimum 24-hour in advance.</span></dd>

                            <dt>SUPPORT RESPONSE GUARANTEE</dt>
                                <dd>Corporate Technology guarantees 24/7 support available 365 days per year. Support is available via our online Contact Us form, via our phone lines and live chat, and the web hosting control panel integrated ticketing system. Each ticket is replied to within no more than 60 minutes after it has been opened.</dd>
                                <dd>Dedicated 24/7 Support For All Customers</dd>
                                    <ol className='normal_ordered_list'>
                                        <li>24/7 Ticketing support with 25 minutes average reply time</li>
                                        <li>2 US tel. support lines are available from 4am to 8pm PST</li>
                                        <li>An additional UK tel. support line is now available</li>
                                    </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
