import React, { Component } from 'react'

export default class PageNotFound extends Component {
  render() {
    return (
        <div id='page-not-found'className='notFoundPage'>
            <div className="page_container flex_center">
                <div className='logo_404'>
                    <p>4  4</p>
                    <img src="/imgs/circle_logo.gif"/>
                </div>
                <h1>The page you're looking for <br></br>does not exist or has been deleted.</h1>
                <a className='404_button' href='/'>Go Back to Home</a>
            </div>
        </div>
    )
  }
}
