import React, { Component } from 'react'



export default class GT_PrivacyPolicy extends Component {
    render() {
        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Privacy Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology Privacy Policy</h1>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                   <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>PRIVACY OF YOUR PAYMENT DETAILS</dt>
                                <dd>We, at Corporate Technology, use an encrypted SSL connection to process Customers' credit card information. Payments processed by Corporate Technology, a US company headquartered in California, USA, will require us to store your billing information including credit card information on our servers and payments processed by Corporate Technology. Corporate Technology will not store any billing information on our servers. Working with some of the leaders in online payment operations, we guarantee fast and secure processing of Customers' payments. For maximum online security - we do NOT share this information with any third party.</dd>
                            <dt>PRIVACY OF YOUR ACCOUNT</dt>
                                <dd>We consider your registration data and the content of all your messages private and confidential. The header of the messages that you send will contain your name (e-mail address) and the Internet Protocol (IP) address. We will not intentionally monitor or disclose any of your e-mail content or registration data unless we are required to do so:</dd>
                                    <ul>
                                        <li>to enforce these Terms of Services,</li>
                                        <li>by law,</li>
                                        <li>to defend ourselves in any legal action,</li>
                                        <li>to protect our property.</li>
                                    </ul>
                                <dd>Since your password is essential for protecting your privacy, we encourage you to NOT share it with anyone and change it frequently. Additionally, you agree to respect the privacy and confidentiality of others.</dd>
                            <dt>PRIVACY OF YOUR REGISTRATION DATA</dt>
                                <dd>Corporate Technology collects certain information during the registration process. The personally identifiable information that Corporate Technology collects includes first and last name. Corporate Technology also collects non-personally identifiable information, such as country, age, gender, etc. You may update your Registration Information at any time from your Web Hosting Control Panel - > My Account - > Change Login Credentials section.</dd>
                                <dd>We may disclose aggregate registration statistics in connection with our marketing activities.</dd>
                            <dt>SECURITY OF INFORMATION</dt>
                                <dd>Corporate Technology is committed to protecting the security of customers’ collected personal information. To do so, we use technical security measures to prevent the loss, misuse, alteration, or unauthorized disclosure of information under our control. We use security measures including and not limited to: physical, electronic, and managerial procedures to safeguard and secure the information we collect online. When we ask customers or users to provide financial information (such as a credit card number) that data is protected using Secure Sockets Layer ("SSL") technology.</dd>
                            <dt>ACCESS TO PERSONAL INFORMATION</dt>
                                <dd>Corporate Technology's customers have access to their data including full name, company name, email address, and credit card information. Customers can delete data related to their full name, company name, and identifiable billing information which will no longer be stored in our databases, except for email address, network logs, cases where fraudulent or otherwise illegal activity has been deemed to occur as determined by law enforcement or Corporate Technology management, in which case the information may remain in our database indefinitely to help facilitate the prevention of repeated abuse in the future from the offending party.</dd>
                            <dt>WHY WE NEED THIS INFORMATION</dt>
                                <dd>Your registration information is needed for us to operate the site, to provide support for your account, for statistical purposes, etc. Corporate Technology will NOT send you any unsolicited information, including e-mails, except as mentioned hereby.</dd>
                                <dd>New customers will receive a Corporate Technology welcome e-mail containing information about the features provided by the Service, as well as WHCP (Web Hosting Control Panel) and FTP login details. Corporate Technology may also send system notifications upon need, related to any upgrade, maintenance, or other service issues concerning your hosting account.</dd>
                                <dd>Corporate Technology will keep your personally identifiable information private and will NOT share it with any unauthorized third parties.</dd>
                            <dt>LOG FILES</dt>
                                <dd>We use IP addresses to analyze trends, administer our site and servers, track access, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information. Personal information about a customer may be included in the log files due to the normal functions of IP addresses and Web browsing.</dd>
                            <dt>COOKIES</dt>
                                <dd>We use different cookies on our website to make it as useful to you as possible. Cookies are small text files that we put on your computer or mobile device to tell us what you want to use on our site but they do not tell us who you are.</dd>
                                <dd>Here you can learn more about cookies and how to control or delete them.</dd>
                                <dd>Here are the cookies that we use on our site:</dd>
                                <dd>Essential cookies</dd>
                                <dd>We use a cookie to remember your login for you and what you’ve put in the shop cart. Also, we set a cookie to help you choose a preferred font size for using the site. These cookies we consider essential for the operation of our website.</dd>
                                <dd>External web services</dd>
                                <dd>We use an external live chat service (using cookies to remember your session name) to deliver real-time support to you should you want to.</dd>
                                <dd>Payment processing</dd>
                                <dd>To process payments online, we require the billing address for the credit card used, and our external payment provider, PayPal/WorldPay, requires your credit card details.</dd>
                                <dd>We assume you are happy with this usage of cookies on this website. If you are not happy, then you should either not use this site, or delete the cookies (you can check out how to do that according to your browser here), or browse the site using your browser’s anonymous usage setting (named “Incognito” in Chrome, “InPrivate” for Internet Explorer, "Private Browsing" in Firefox and Safari, etc.).</dd>
                                <dd>Please remember that if you choose to delete the cookies or use the browser anonymously, you may find that certain sections of this website do not work properly.</dd>
                            <dt>OPT-OUT</dt>
                                <dd>By default, customers do receive any system updates, such as the newsletters, invoices, and service email notifications. Any customer who voluntarily receives these updates may unsubscribe at any time. Customers may not opt-out of possibly receiving important system notifications, in the rare event that such notification would be necessary.</dd>
                            <dt>COMPLIANCE WITH LAWS AND LAW ENFORCEMENT</dt>
                                <dd>Corporate Technology cooperates with government and law enforcement officials to enforce and comply with the law. We will disclose any information about Users upon valid request by government or law officials as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including without limitation subpoenas), to protect your property and rights, or the property and rights of a third party, to protect the safety of the public or any person, or stop activity we consider illegal or unethical.</dd>
                            <dt>CHANGES TO PRIVACY POLICY</dt>
                                <dd>Any changes to our Privacy Policy will be placed here and will supersede this version of our Policy. We will take reasonable steps to draw your attention to any changes in our Policy. However, to be on the safe side, we suggest that you read this document each time you use the website to ensure that it still meets with your approval.</dd>
                            <dt>CONTACT US</dt>
                                <dd>If you have any questions about our ID Protection Service Agreement, please use our  <a href="https://www.corporatetech.com/contact-us"> Contact Us Form.</a></dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
