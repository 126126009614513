import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import HeroSoftdev from '../components/HeroSoftdev'
import CustomerCarousel from '../components/CustomerCarousel'
import SoftdevServices from '../components/SoftdevServices'
import { pageContact } from '../common/data'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class SoftDev extends Component {
    render() {
        const { consultant, pdf_file } = pageContact
        return (
            <div id="softdev-page" className='services-page'>
                <LazyLoad height={200} once >
                <HeroSoftdev 
                    title="SOFTWARE DEVELOPMENT"
                    header="Software Development by the numbers"
                    data1="92"
                    p1="of business that outsourced software development experienced a spike in employee productivity."
                    data2="43"
                    p2="is the average decrease in project turn around time."
                    data3="50"
                    p3="is the minimum reduction in development costs."
                    
                />
                </LazyLoad>
                <LazyLoad height={200} once >
                <SoftdevServices />
                </LazyLoad>
                <VideoSection
                    title='California Closets: CorpTech Proactively Solves Our Technology Problems'
                    poster='/videos/posters/retail_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/retail_industry_california_closets_case_study.mp4'
                />
                <div id="softdev-customercard">
                    <CustomerCarousel />
                </div>
                <div className="page-contact">
                    <div className="page-contact--img" data-aos-anchor-placement="center-bottom" data-aos="fade-right" />
                    <div data-aos-anchor-placement="center-bottom" data-aos="fade-left" className="page-contact--actions">
                    <Link to="/contact-us" className={`action--contact ${consultant}`} />
                        <a href={`${pdf_file}`} className='action--download' rel="noopener noreferrer" target='_blank' download>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
