import React, { Component } from 'react';
import TermsTablePrice from '../../TermsTablePrice'


export default class WebHosting extends Component {
    gotoTop = () => {
        window.scroll(0,0)
    }
    componentWillMount() {
        // const btnTop = document.getElementById("to_top_btn");
        
        // function onScrollTop() {
        //     console.log('ni')
        //     if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        //         btnTop.style.display="block";
        //     }
        //     else {
        //         btnTop.style.display = "none";
        //     }
        // }
        // window.onscroll = function() {onScrollTop()};
    }
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        // const priceTable = termsTableData.map(d => {termsTableData})
        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">CORPORATE TECHNOLOGY WEB HOSTING TERMS OF SERVICE</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Terms of Service</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred as 'Corporate Technology' in this document.
                    </p>
                    <p className="terms__description--padding-small">As a Customer of our Services, you need to be aware that, when signing up for an account with Corporate Technology, you accept and agree to abide by our Web Hosting Terms of Service(this document) and our Acceptable Use Policy(UAP). The Order Form completion of the Service represents the electronic signing of these two documents.
                    </p>
                    <p className="terms__description--padding-small">As a Customer of our Services, you understand and agree also that it is beyond the power of Corporate Technology to restrict any public access to the data that you position on our servers and guarantee the security of your files. In this respect, you accept that it is your obligation to protect all the information that you make available to the wide internet public through our web hosting network and assume all responsibility for unveiling online personal data that could be abused.
                    </p>
                    <div className="container-divider">
                        <ul> 
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <ol className='colored_ordered_list'>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_entire_agreement">Entire Agreement; Service Agreement</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_corporate_technology">Corporate Technology Hosting Services</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_service_activation">Service Activation and Continuance Timescales</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_free_domain">Free Domain Name Promotion</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_service_expire">Service Expiration and Renewal</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_customer_response">Customer Responsibilities</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_no_unlawful">No Unlawful of Prohibited Use</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_e_commerce">E-Commerce Customer Websites</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_third_party">Third Party Dealings</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_cancellation">Cancellations, Refunds & Chargebacks</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_new_releases">News Releases</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_torturous_conduct">Torturous Conduct</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_force_majeure">Force Majeure Circumstances</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_service_provision">Service Provision; Warranties and Representations</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_service_discontinuance">Service Discontinuance</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_complaints_procedure">Complaints Procedure</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_customer_service">Customer Service Contact Details</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_revisions">Revisions</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_assignment">Assignment</a></li>
                            <li><a href="https://corporate-tech.dnaqa.net/hosting-terms#wh_termination">Termination</a></li>
                        </ol>
                        <dl className='terms_individual_details'>
                            <dt id="wh_entire_agreement">1. ENTIRE AGREEMENT; SERVICE AGREEMENT</dt>
                                <dd>These Web Hosting Terms of Service, together with our Acceptable Use Policy and the Domain Name Registration Agreement, constitute the whole act of convention between You - the Customer, and Corporate Technology. Corporate Technology shall provide its Services to You under the Terms and Conditions explicated in this Service Agreement. The Agreement is intended to clarify all aspects, rules and provisions of the business relationship between You - the Customer, and Corporate Technology. It is also intended to keep the integrity of this relationship and protect both parties from liability. This Service Agreement will be enforced, if/when necessary, in order to be guaranteed the speed, power, performance, and reliability of our services.</dd>
                                <dd>In the event that a court of competent jurisdiction declares any of the Agreement's provisions invalid, unenforceable, or void, the remaining provisions will entirely preserve their validity. Customer's side of the Agreement can be presented by any of the following parties: an individual over 18 years of age; a legal entity, such as a corporation, a limited liability company, etc. If a person represents the Customer, for some reason, he/or she must be legally authorized to act on his behalf, thus accepting, executing and delivering the Agreement.</dd>
                                <dd>This agreement shall be interpreted in consistency with the laws of the State of Florida, US, and shall serve to the benefit of the parties hereto and their respective heirs, executors, administrators, successors and assigns.</dd>
                            <dt id="wh_corporate_technology">2. CORPORATE TECHNOLOGY HOSTING SERVICES</dt>
                                <dd>A web hosting service is a type of Internet service that offers individuals and organizations/companies the resources to host their own web sites and store information, images, video, and other content accessible via the World Wide Web. A Storage Service Provider (SSP) is a company that provides computer storage space and related management services. SSPs also offer periodic backup and archiving.</dd>
                                <dd>Corporate Technology offers only web hosting services, not data storage services. A web hosting service consists of the following:</dd>
                                <dd><b>SERVICE FEATURES</b></dd>
                                    <ol>
                                        <li><b>Disk Space </b>- We use a clustered storage technology. This means that the storage resources of a hosting plan purchased from us are not confined to a single server, but are distributed between multiple machines. This way optimal performance is guaranteed and higher server stability. It also ensures that you will be able to avail of all the storage quota that has been assigned to your particular account. Additional disk space is available as an upgrade option. Payment is accepted only in advance per year. Contact sales for more information.</li>
                                        <li><b>Traffic </b>- Each hosting plan includes prepaid monthly traffic in Megabytes. This traffic quota is shared between all sites hosted in a single account. In case that a hosting account exceeds its allowed traffic quota, it will be suspended until the beginning of next month when the quota will refresh automatically. Additional traffic is available as an upgrade. Contact sales for more information.</li>
                                        <li><b>Hosted Domains </b>- Corporate Technology's hosting plans include domain hosting. Each hosting plan allows a particular number of domains that can be hosted.</li>
                                        <li><b>Domain Registration/Transfer </b>- available at regular or a discounted price (depending on the chosen TLD) with the purchasing of a web hosting account. Each account can host a maximum of one discounted domain name. Domain registrations are final and are not subject to refund. More information regarding domain names can be found further in this TOS (Terms of Service), in our Domain Name Registration Agreement and in the Domain Name Dispute Resolution Policy.</li>
                                        <li><b>Email accounts </b>- different number of email accounts are available for setup with each web hosting plan. </li>
                                        <li><b>MySQL/PostgreSQL Database(s) </b>- MySQL databases are included in all web hosting plans. Additional databases are available as an upgrade. PostgreSQL databases can be either included or optional. Contact sales for more information.</li>
                                        <li><b>Database Storage </b>- Each hosting account has a limit of database storage. This storage is shared between all the databases in a single hosting account. Additional database storage is available. Contact sales for pricing.</li>
                                        <li><b>IP Address </b>- Corporate Technology's hosting accounts do not include dedicated IP addresses, unless listed in plan features. Dedicated IP addresses can be purchased at any time as an upgrade to the web hosting service provided by Corporate Technology. Dedicated IP addresses are purchased for a period of at least one year and are non-transferable to other web hosting providers. Contact sales for more information.</li>
                                            <p>NB: Please note that according to IANA - the entity that coordinates the global pool of IPs, newly requested dedicated IP addresses must be used within 7 days of their allocation. So, if you request a dedicated IP address from your Control Panel and do not assign it to a host within 7 days of the allocation date, then the IP address will be taken back to the global pool of IPs. If so, you will need to re-request the IP address from the Dedicated IP Addresses section of your Control Panel.</p>
                                        <li><b>SSH </b>- In case your hosting account does not include SSH, please contact the sales department for more information on how to obtain it. Payment is accepted only in advance per year.</li>
                                        <li><b>ID Protection </b>- This service ensures that your private contact information will not be visible in the domain's WHOIS details. Optional for all hosting plans, but available only with certain TLDs, supporting this option.</li>
                                    </ol>
                                <dd>Detailed information about all features included in our web hosting packages is available on our web hosting plan pages.</dd>
                                <dd><b>INSTALLATION AND TROUBLESHOOTING</b></dd>
                                <dd>Each customer will be able to take advantage of our Installation & Troubleshooting service upgrade. It allows users to get one-time expert assistance from our technical support team or system administrators for a period of 1 hour. The Installation & Troubleshooting is intended for:</dd>
                                    <ul>
                                        <li>Hacked sites cleaning and restoration service</li>
                                            <p>If you find your website hacked or reported to be infected you can rely on our help getting this fixed for you. This service included a backup of the current content, cleaning and/or restoring broken files from original script installation or restore from a previous copy.</p>
                                        <li>Script, theme, plugin installation service</li>
                                            <p>If you need any software installation, theme/template or even plugin installation that you do not wish to deal with, we can do it for you. We will backup the current site (if any) and complete the desired installation for you.</p>
                                        <li>Scripts troubleshooting and debugging services</li>
                                            <p>In case you have an issue with a software that you are unable to handle, we can do the troubleshooting and fix it for you.</p>
                                        <li>Manual files, database or emails transfers between hosting accounts using our services. If you want to move a site or few over to another account we can handle the migration for you hassle free.</li>
                                    </ul>
                                <dd><b>The Installation & Troubleshooting will not apply in the following cases:</b></dd>
                                    <ul>
                                        <li>Our installation service covers only fresh installations (no upgrades). We will not perform custom work or upgrade to a recent version of an already existing script.</li>
                                        <li>The service will not include any configuration of the scripts beyond their installation. After the scripts are being activated and operational any further tweaking and setup is done by the client.</li>
                                        <li>We will only provide installation and troubleshooting if the hosting is provided by us on our Linux Based Servers. We do not provide the service for any Windows, Linux and MAC servers which are not powered and managed by us.</li>
                                        <li>We don’t provide any warranties and will not be held responsible for any data loss or any script, theme, plugin not operating as intended.</li>
                                    </ul>
                                <dd><b>"UNLIMITED" USAGE POLICIES AND DEFINITIONS</b></dd>
                                <dd>The term “UNLIMITED” is used to describe the amount of resources in the web hosting plans provided by Corporate Technology, which is not set to a predefined limit value (for example - unlimited bandwidth, unlimited disk space, unlimited emails or other unlimited resources). By not setting limits on key resources, we are able to offer our customers a simple, consistent pricing scheme as their websites demand more and more resources. As a result, a typical website may experience periods of great popularity and a resulting increased storage need without experiencing any associated increase in hosting charges.</dd>
                                <dd>In all cases, when a service is listed as “Unlimited“, it is intended for normal web hosting usage only - all activities, which may result in excessive resource usage inconsistent with regular usage patterns, are prohibited.</dd>
                                <dd>Our Unlimited service offers are not designed to allow a single user to influence the overall server performance. Corporate Technology will make every effort to provide its customers with additional resources whenever possible, including the relocation of a customer's hosting account to a newer, faster server. However, if the resource usage reaches a level where it may influence the overall server performance in a negative way, Corporate Technology reserves the right to take corrective actions. Such actions may include disconnection or discontinuance of any and of all offered services, removal or deletion of customers' websites, website content, electronic mail, or termination of a customer's account according to Corporate Technology's sole and absolute discretion.</dd>
                                <dd><b>SERVICE PAYMENTS AND RECURRING CONTRACTS</b></dd>
                                <dd>When signing up for an account with Corporate Technology, the Customer agrees to pay for the period of one year (365 days) and abide by our Order Verification Policy. Our Sales Department will strictly scrutinize each account order and carefully processes each payment in order to ensure that no fraudulent user can take advantage of our web hosting system. In cases of PayPal and American Express credit card payments, a 12-month recurring billing cycle will apply for customer convenience. In case that a customer wants to cancel their recurring contract, they can do so from the Recurring Manager section of their Web Hosting Control Panel.</dd>
                                <dd>Corporate Technology will charge customers with fees based on the fee structure of our web hosting system at the time of the sign-up process. Corporate Technology reserves the right to introduce price changes to the packages when needed. This means that the current price list at our web store may differ from the pricing of your web hosting account.</dd>
                                <dd>Each customer of ours will be able to take advantage of the package he/she has purchased at the introductory price levels, and with one and the same set of features in the course of the whole contract period. Corporate Technology reserves the right to introduce certain changes to the packages (in the form of complementary services) to the benefit of the customers.</dd>
                                <dd>When you pay for a web hosting plan with a domain via credit card or PayPal, a 12-month recurring billing subscription will be created automatically for your convenience, for which you will get a notification from the payment processor. All automatic payments go through exactly 7 days before the due date so as to avoid any downtime to your websites if there is a problem with the credit card/PayPal account.</dd>
                                <dd>We’ll send you a reminder of the due payment at least 24 hours before the automatic charge takes place.</dd>
                                <dd>You can cancel the subscription at any time from your Control Panel -> My Account -> Recurring, or directly through PayPal - if it’s a PayPal account subscription.</dd>
                                <dd><b>SERVICE GUARANTEES</b></dd>
                                <dd>Corporate Technology guarantees 99.9% network uptime and server stability. This, however, does not refer to problems stemming from: </dd>
                                <dd>Server Hardware Breakdown;</dd>
                                <dd>User error(s) or purposeful interruption(s) of the user service (e.g. if the client shuts his/her own server down, Corporate Technology is not responsible for the downtime);</dd>
                                <dd>Failure due to software that is not explicitly supported by Corporate Technology. If a hardware crash provoked by the customer consequently occurs, Corporate Technology is beyond the control and responsibility for the resulting downtime.</dd>
                                <dd>For more information, please read our <a href="https://www.corporatetech.com/service-level-guarantee">Service Level Guarantees.</a></dd>
                                <dd><b>ACCOUNT/DATA BACKUP</b></dd>
                                <dd>Our backup service is provided as a courtesy to you and therefore, Corporate Technology will be in no way held responsible for the files stored in your account. You, as a customer, agree to take full responsibility for all the files in your account and to maintain an appropriate backup of the data stored on our servers. We will back up the files located in the /www folder of your account, as well as the databases you have created. Backups are only kept for a period of 7 days. After this period, a backup cannot be restored.</dd>
                                <dd><b>General Backup: </b>The default available backup disk storage space for each customer is 5 GB. User accounts with content over 5 GB cannot be (partially or entirely) backed up. Additional space for backup purposes is available under our Upgrade section in the Control Panel. We provide backup services only to accounts with disk space usage below the purchased backup quota limit. For example, if the purchased data backup quota amounts to 20 GB, we will back up your files as long as your account's disk usage does not exceed the 20 GB quota limit. As soon as this 20 GB quota limit has been reached - the backup script will no longer back up any of your files.</dd>
                                <dd><b>Database Backup: </b>Our database backup script WILL NOT back up databases, which contain more than 256 tables. Databases bigger than that take too much time and use too much of the server's resources to be backed up.</dd>
                            <dt id="wh_service_activation">3. SERVICE ACTIVATION AND CONTINUANCE TIMESCALE</dt>
                                <dd><b>30-DAY FREE TRIAL</b></dd>
                                <dd>All our web hosting plans include a 30-day free trial option, which can be enabled as soon as a given Customer has created an account. Only new Customers can take advantage of this possibility. The free trial period itself will expire 30 days after activation.</dd>
                                <dd>During the free trial period, the account will operate with several limitations, which may differ based on the chosen package. More information on the subject will be sent to the Customer on the free trial period's start date. After the trial period ends, the Customer will no longer have access to the Service unless they complete their purchase.</dd>
                                <dd>All customer's data will be kept for up to 30 days after the end of the free trial period. In case that the customer does not complete the order procedure within that period, the data will be deleted from our servers.</dd>
                                <dd>At any given time during the free trial period, the Customer may convert their free account into a standard paid account by clicking the “Complete Order” button in the Web Hosting Control Panel.</dd>
                                <dd><b>SERVICE ACTIVATION</b></dd>
                                <dd><u>For newly signed up clients </u>- the services are activated immediately after our Sales Department gets in touch with the customer over the phone to verify the payment details (this is required due to the high fraud activity spreading online, and in order to prevent fraudulent orders). The payment verification procedure is obligatory and if we fail to approve a transaction within 48 hours of the payment submission, the funds will be credited back to the payer and the order will be cancelled.</dd>
                                <dd><u>For existing customers </u>- the services for existing customers, including upgrade services and renewals, are activated immediately after our Sales Department receives the according service payment.</dd>
                                <dd><b>CONTINUANCE TIMESCALES</b></dd>
                                <dd><u>Shared Web Hosting Accounts </u>- all newly-created shared hosting accounts with Corporate Technology are valid for 1 year. They can be regularly renewed for another year that is added to the official expiration date of the account. All account upgrades purchased from the Web Hosting Control Panel are valid and are to be functional till the final expiration date of the respective web hosting account.</dd>
                                <dd><u>Domain Name Registration/Transfer </u>- Corporate Technology offers for registration/transfer all TLDs (Top-Level Domains) displayed in the table below. The TLDs can be purchased either together with a shared hosting account (at regular or PROMOTIONAL price) or separately as an upgrade from the Web Hosting Control Panel (all TLDs - at NON-PROMOTIONAL prices). The table below displays the minimum and maximum registration period, as well as the annual NON-PROMOTIONAL price of each TLD:</dd>
                                <TermsTablePrice/>
                            <dt id="wh_free_domain">4. FREE DOMAIN NAME PROMOTION </dt>
                                <dd>Corporate Technology occasionally runs promotions offering FREE domain names to customers signing up for a new web hosting account. This special offer is valid only with our PROMOTIONAL TLDs. However, in case that the money-back guarantee option is used, the domain name must be paid ($9.95 per year per domain name). The domain name owner will have access to a domain management account, and will be able to transfer the domain name to another registrar.</dd>
                            <dt id="wh_service_expire">5. SERVICE EXPIRATION AND RENEWAL</dt>
                                <dd><b>HOSTING SERVICE</b></dd>
                                <dd>The hosting service is active for one year (365 days). After the expiration of that period, the account will be automatically suspended and will remain suspended until the Customer renews it by paying its annual subscription fee. In case that the Customer does not renew his/her hosting account, it will remain BLOCKED. The account data will be kept for two months, after that it will be permanently deleted.</dd>
                                <dd>Email notifications regarding each account's expiration are dispatched twice - 14 days and 7 days prior to the expiration date of the account. In order to receive the notifications, the Customer must provide a valid email address. Corporate Technology ensures that the emails are sent and takes no responsibility for emails that are not delivered due to problems with the recipient's email address.</dd>
                                <dd><b>DOMAIN NAMES</b></dd>
                                <dd>Domain names are valid for the number of years of their registration (domain names can be registered for the period of 1 to 10 years, depending on their TLD - top-level domain name - extension). After an expiration of a domain name several stages follow regarding its renewal.</dd>
                                    <ul>
                                        <li>The domain name Redemption Grace Period (RGP) is a registry level period that starts about 30 days after the domain name's expiration date. During this period the domain name is kept on hold by the Registrar before it gets deleted. The domain registrant is allowed to retrieve the domain name from deletion, but this process costs additional redemption fee. The RGP fee depends on the given TLD and on the registrar through which it is registered as follows:</li>
                                    </ul>
                                <table className="terms_table">
                                    <tr>
                                        <th>Registrar</th>
                                        <th>TLD</th>
                                        <th>RGD fee</th>
                                    </tr>
                                    <tr>
                                        <td>Corporate Technology</td>
                                        <td>.com, .net, .org, .info, .biz</td>
                                        <td>$90.00</td>
                                    </tr>
                                    <tr>
                                        <td>LogicBoxes</td>
                                        <td>.us, .in, .cc, .tv</td>
                                        <td>$90.00</td>
                                    </tr>
                                    <tr>
                                        <td>EURid</td>
                                        <td>.eu</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>eNom</td>
                                        <td>.com, .net, .org, .info, .biz, .co, .asia, .ca, .name, .mobi, .tw, .com.tw, .org.tw, .idv.tw, .pro, .me</td>
                                        <td>$270.00</td>
                                    </tr>
                                </table>
                                <dd>The RGP period extends the time available for renewing domain names. However, any website or email services associated with the domain name will stop working and the domains that enter a redemption period may be removed from the zone files (the list of domain names currently in the global DNS).</dd>
                                    <ul>
                                        <li>After the Redemption Grace Period (or the renewal period – if RGP is not applicable) has passed, domain name(s) enter the so called ERGP (Extended Redemption Grace Period). During the first 120 days of the period in which the domain name is appointed to its ERGP period you are allowed to redeem the domain name and take ownership once again if you pay the fee for redeeming the domain name with all registration fees. When the 120 period lapses and you have not redeemed the domain name, the domain name will be considered deserted and you would have no further rights over the domain name registration.</li>
                                    </ul>
                                <dd>At that point domain name(s) can be: provided to third parties; Corporate Technology may choose to auction the domain name(s); Corporate Technology may return them to the public registration pool. All of these actions can be carried out at any time and without your consent.</dd>
                                    <ul>
                                        <li>After the redemption period of an expired domain name is finished, comes the <b>Pending Delete Period </b>- a registry level period of up to 5 days during which no one is allowed to renew the domain name and it cannot be newly registered because it's still not returned to the public pool of domain names.</li>
                                        <li><b>Released by Registry Period </b>- the domain name is sent back into the public pool of domain names and is newly available for registration.</li>
                                    </ul>
                                <dd>Customers are advised to renew their domain registrations before their domain names are placed into redemption. When a domain name transits in the redemption period, we are not able any more to prevent its zone files from being removed from the DNS system, and the customer's web site and any associated services will stop working.</dd>
                                <dd>During a domain name's redemption period, we can assist the customer in retrieving his/her domain name from deletion only if the domain name was registered with us. The customer will need to pay a REDEMPTION FEE (see the table above for more information) for the domain registration renewal (the redemption fee is imposed by our Registrar). If the domain name was registered with another registrar, the customer needs to contact that registrar for more information regarding its retrieving.</dd>
                            <dt id="wh_customer_response">6. CUSTOMER RESPONSIBILITIES </dt>
                                <dd><b>ACCOUNT REGISTRATION AND MANAGEMENT</b></dd>
                                <dd>In consideration of their use of the Service, customers agree to:</dd>
                                    <ul>
                                        <li>Provide true, accurate, current and complete information about themselves as prompted by the registration form of the Service. Maintain and promptly update their <b>registration data</b> to keep it true, accurate, current and complete. If the customer provides any information that is untrue, inaccurate, not current or incomplete, or Corporate Technology has reasonable grounds (at its sole discretion) to suspect that such information is untrue, inaccurate, not current or incomplete, Corporate Technology has the right to suspend or terminate the customer's account and refuse any and all current or future use of the Service (or any portion thereof). If untrue details are used in order to avoid VAT the customer can be held responsible by the respective national tax collecting agency.</li>
                                        <li>When introducing any changes to their <b>contact details</b>, customers are obliged to notify Corporate Technology in the course of 10 business days after the changes become valid. The introduction of changes should be done online through the customers' Web Hosting Control Panel, not via email. This requirement is also in force for Corporate Technology, i.e. customers will be always aware of any contact information changes that the company places within its website. This way, flawless communication between both sides is guaranteed.</li>
                                        <li>Customers will receive a <b>password and account designation</b> upon completing the registration process of the Service. Customers are responsible for keeping the confidentiality of their passwords and accounts, and are fully responsible for all activities that occur under that password or account. Customers agree to: a) immediately notify Corporate Technology about any unauthorized use of their password or account or any other breach of security; b) ensure that they safely exit (log out from) their account at the end of each session. Corporate Technology cannot and will not be liable for any loss or damage of account information arising from Customer's failure to comply with this provision.</li>
                                        <li>If a hosting account is registered with details different than those of the person who paid for the account, and in the case of any dispute, Corporate Technology will take the side of the person who paid for the account and shall not provide access to any other person, since - a hosting account owner is and will be considered to be the credit card /PayPal account holder.</li>
                                        <li>If the hosting account owner deceased prior to the expiration of his/her account, Corporate Technology will require a death certificate or any other legal document that can provide an official evidence for that fact. Those who supply that information will be held legally responsible if the information is false or incorrect. Once such documents are obtained, Corporate Technology will be the sole arbiter in assessing whether the documents are authentic or not. A record of the obtained documents will be kept until the expiration of the hosting account.</li>
                                    </ul>
                                <dd><b>ACCOUNT USAGE</b></dd>
                                    <ul>
                                        <li>CPU usage(or usage of the Central Processing Unit(s) of the web server the customer's account is hosted on) - The CPU usage is subject to some regulations. Each hosting account can use up to 10% of the CPU power for no longer than 180 seconds at any given time, and/or the percentage given per 24 hours in the features configuration of the Customer's plan. Corporate Technology's web hosting plans include the following CPU quotas (per 24 hours).</li>
                                    </ul>
                                <table className="terms_table">
                                    <tr>
                                        <th>Web Hosting Plan Name</th>
                                        <th>CPU Limit</th>
                                    </tr>
                                    <tr>
                                        <td>GetStarted</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>GetMore</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>GetMorePlus</td>
                                        <td>7%</td>
                                    </tr>
                                    <tr>
                                        <td>GetPro</td>
                                        <td>10%</td>
                                    </tr>
                                </table>
                                <dd>Corporate Technology strongly recommends that customers be very judicious when using the CPU power for their accounts. We reserve the right to block files or scripts that excessively use CPU. We may also suspend the Customer account in the case of registered impact on other accounts on the server or disturbance caused to its functioning. If we do block or suspend an account, then its owner will be notified of the situation and given an explanation, and/or offered to upgrade to a dedicated or a semi-dedicated web hosting solution.</dd>
                                    <ul>
                                        <li><b>Emails </b>- Users are not allowed to send more than 1500 emails per day/150 emails per hour per hosting account (each email can be sent to a maximum of 50 recipients, as each unique recipient will be counted as one message sent). Users are not allowed to send more than 1500 emails a day using a mailing list (or 1 email to 1500 subscribers, respectively). Each email account is entitled to 10 GB of storage space, while all active email accounts under a hosting account must not exceed 40 GB in size. Only the GetPro plan has an email storage quota of 60 GB. Each email account can host up to 200.000 stored messages.</li>
                                    </ul>
                                <dd>You can manage as many mailboxes as you need under one account as long as you do not interfere with the other users on the server.</dd>
                                <dd>If there are more than 3000 mailboxes in a given hosting account, its owner can cause high server load by just modifying a password or changing an alias.</dd>
                                <dd>This is why, if a particular mailbox simultaneously accommodates multiple emails and its owner often executes changes like the ones mentioned above, then we’ll be forced to disable that account and ask the respective client to migrate to a more resource-rich type of a hosting environment where mailbox modification operations cannot lead to server performance issues.</dd>
                                <dd>Several specific limitations apply to the Domain Manager plan-included email service such as a maximum of 250 outbound emails per day/25 emails per hour. Your customers can see the specific disk storage quota allocation and the number of emails allowed in the Account Usage table in the Hepsia Control Panel.</dd>
                                    <ul>
                                        <li><b>File Quota </b>- There is no file quota limit for any one of our web hosting packages. You are allowed to have an unlimited number of files in your hosting account.</li>
                                        <li><b>Disk Space & Traffic </b>- Corporate Technology offers generous amounts of disk space and network bandwidth. Customers must not allow other websites or hosting servers to link to files stored in their hosting accounts, and thus steal network traffic. In order to escape unjust distribution of the resources, as well as possible sharing of illegal content, Customers are not allowed to use their hosting accounts as a remote storage server only or solely for the purpose of sharing (un)archived images, programs or sound files. At least 80% of all files and/or total megabytes used (including MP3, WAV, RA, GIF, JPG, ZIP, RAR, but not limited to these file types) in each hosting account must be a part of actual, active website(s) content, i.e. all content files must have corresponding HTML or PHP files that link to them. Audio, graphics and/or video files must be under 25% of the current web hosting disk usage and must never exceed 50 GB in size. Furthermore, such media files may amount to no more than 50% of the customer's total monthly traffic usage. Upon request, each Customer must be able to prove that a given file is a constituent part of an actual page and not only stored on the server within 24 hours of the request. If the client fails to react within 24 hours after the request and does not remove the file/files in question, or if the apportioned traffic quota continues to be used for such purposes - the Provider reserves the right to suspend the account and/or to remove the aforesaid content from the server. In such cases, refunds are not applicable and the money-back guarantee is no longer valid.</li>
                                        <li><b>MySQL usage </b>- each database hosted in our system can be used only by the user who created it. Another user, whose account is hosted on the same or on another server, cannot use the database created in the first-mentioned customer account. The MySQL database has a limit of 45,000 queries per hour. If you have reached 45,000 queries per a given hour, you will not be able to make any more queries. In the next hour you will have access to the database again and the counter will be reset. You can make a maximum of 15 simultaneous connections to a MySQL database.</li>
                                        <li><b>Access and error logs </b>- The access and error logs for all client's hosts in one account should not exceed 500 MB in total.</li>
                                    </ul>
                                <dd>"UNLIMITED" USAGE POLICIES AND DEFINITIONS</dd>
                                <dd>The term “UNLIMITED” is used to describe the amount of resources in the web hosting plans provided by Corporate Technology, which is not set to a predefined limit value (for example - unlimited bandwidth, unlimited disk space, unlimited emails or other unlimited resources). By not setting limits on key resources, we are able to offer our customers a simple, consistent pricing scheme as their websites demand more and more resources. As a result, a typical website may experience periods of great popularity and a resulting increased storage need without experiencing any associated increase in hosting charges.</dd>
                                <dd>In all cases, when a service is listed as “Unlimited“, it is intended for normal web hosting usage only - all activities, which may result in excessive resource usage inconsistent with regular usage patterns, are prohibited.</dd>
                                <dd>Our Unlimited service offers are not designed to allow a single user to influence the overall server performance. Corporate Technology will make every effort to provide its customers with additional resources whenever possible, including the relocation of a customer's hosting account to a newer, faster server. However, if the resource usage reaches a level where it may influence the overall server performance in a negative way, Corporate Technology reserves the right to take corrective actions. Such actions may include disconnection or discontinuance of any and of all offered services, removal or deletion of customers' websites, website content, electronic mail, or termination of a customer's account according to Corporate Technology's sole and absolute discretion.</dd>
                                <dd><b>SERVER USAGE & HOSTED CONTENT</b></dd>
                                    <ul>
                                        <li><b>Server content </b>MUST comply with the parameters stated in this TOS and our AUP (Acceptable Use Policy). Web content requirements exclude the publication of illegal content or content considered inappropriate otherwise. If any of Corporate Technology Services are used for the illegal purpose of SPAM or UCE, then they will be terminated instantaneously. Corporate Technology expressly bans the use of any Corporate Technology hardware or software hosted on a Corporate Technology server for trying to illegally access another hosted machine or network. For more detailed information regarding all prohibited uses of our server network, please closely review the next chapter of these Terms and our AUP (Acceptable Use Policy). If you still have inquiries over the matter, feel free to contact a Corporate Technology Service representative.</li>
                                        <li>We offer free themes with each hosting account, which customers can use for their sites. The images used in the featured sliders, the logo images and background photos are provided to demonstrate the theme only. They are not part of the theme and can't be used in the customers' live site.</li>
                                        <li>If your website has been <b>hacked/ infected with malicious content</b>, we reserve the right to access your hosting account and delete the corrupted files. In this case, there will be no need for your account to be suspended. This way, your website(s) will be brought back to their normal state and you will be able to continue using our service.</li>
                                        <li>Using <b>Corporate Technology temporary URL</b> is not defined as a bandwidth stealing. However customers should not rely on Corporate Technology temporary URL as a basic URL. Traffic processing there is not recommendable. Corporate Technology cannot and will not be held responsible for changing it afterwards. Customers are advised to use it temporarily and redirect their traffic to their own domains.</li>
                                    </ul>
                                <dd></dd>
                            <dt id="wh_no_unlawful">7. NO UNLAWFUL OR PROHIBITED USE</dt>
                                <dd>As a condition for customer use of Corporate Technology Services, You - the Customer, agree that you will not use Corporate Technology Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices, and/or our Acceptable Use Policy (AUP). Corporate Technology servers can be used explicitly and only for legal purposes, and each attempt for taking indecent advantage of the servers, inconsistent with the rules, will be immediately counteracted.</dd>
                                <dd>Customers are solely responsible for the content of their messages. Using Corporate Technology Services in connection with surveys, contests, pyramid schemes, chain letters, junk e-mail, spamming or any duplicative or unsolicited messages (commercial or other) is prohibited.</dd>
                                <dd>In this light, Corporate Technology expressly forbids any breach of Federal, State or Local regulation, including, but not limited to, the transmission of: copyrighted material, material legally judged to be threatening or obscene, and material protected by trade secrets. Corporate Technology servers CANNOT be used for the propagation, distribution, housing, processing, storing, or handling in any way of lewd, obscene, adult or pornographic material, or any other material which is considered impermissible by the company's Terms of Service (this document) and Acceptable Use Policy (AUP). Customers may not attempt to gain unauthorized access to any Corporate Technology Services, other accounts, computer systems or networks connected to any Corporate Technology Services, through hacking, password mining or other means. Customers may not obtain or attempt to obtain any materials or information through any means not intentionally made available by Corporate Technology Services.</dd>
                                <dd>Corporate Technology's management will thoroughly scrutinize all received orders and decide, to their own discretion, which of them do not comply with the officially stated requirements for use of the company servers. Corporate Technology reserves the right to refuse service to unlawful parties. Corporate Technology reserves the right at all times to disclose any information, as Corporate Technology deems necessary, to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in the sole discretion of Corporate Technology.</dd>
                            <dt id="wh_e_commerce">8. E-COMMERCE CUSTOMER WEBSITES</dt>
                                <dd>When buying an allocated space on Corporate Technology servers for the purpose of creating online stores or related e-commerce activities websites, customers agree to be fully responsible for the development, operation and maintenance of their web sites, online stores and e-commerce activities within that website. The Customer is the only one in charge of approving, processing and filling clients' orders and taking care of clients' requests or complaints. The Customer is also the only one in charge of the payment or satisfaction of any and all taxes related to his/her web site and online store. The Customer bears the responsibility for ensuring the security and confidentiality of any customer data (including, without limitation, customer credit card numbers) that he/she may receive in connection with his/her web site or online store. Each customer is in charge of all products and services, as well as all contents and materials appearing online on his/her e-commerce website, including:</dd>
                                    <ul>
                                        <li>the accuracy and relevance of Customer Content, and content and material showcased in the Customer store or on its products;</li>
                                        <li>certainty that the Customer Content and content and materials appearing in the Customer store or on its products do not violate or infringe upon the rights of any person;</li>
                                        <li>certainty that the Customer Content and the content and materials appearing in the Customer store or on its products are not calumnious or somehow illegal.</li>
                                    </ul>
                                <dd>Each Customer guarantees to Corporate Technology that he/she owns the right to use the Customer Content and its material, including all text, graphics, sound, music, video, programming, scripts and applets. Each Customer warrants to Corporate Technology that the use, reproduction, propagation and transmission of the Customer Content and any information and materials contained inside do not, and will not:</dd>
                                    <ul>
                                        <li>infringe or abuse any copyright, patent, trademark, trade secret or any other proprietary right of a third party;</li>
                                        <li>break any criminal law or manifest false advertising, unfair competition, vilification, privacy intrusion; violate a right of publicity or violate any other law or regulation.</li>
                                    </ul>
                                <dd>Each Customer empowers Corporate Technology to reproduce, copy, use and propagate all and any part of the Customer Content for ensuring and operating the Services. Corporate Technology is granted the right to estimate, at its own discretion, the extent to which these operations need to be carried out.</dd>
                            <dt id="wh_third_party">9. THIRD PARTY DEALINGS</dt>
                                <dd>Any dealings with third parties (including advertisers) carried out through Corporate Technology Services, including participation in promotions, payment for goods and services, and/or any other terms, conditions, warranties or representations associated with such dealings or promotions, are solely between the Customer and the advertiser or the other third party. Corporate Technology shall not be responsible or liable for any part of such dealings or promotions.</dd>
                            <dt id="wh_cancellation">10. CANCELLATION, REFUND & MONEY-BACK; CHARGEBACKS</dt>
                            <dd><b>CANCELLATION & REFUNDS</b></dd>
                                <dd>Corporate Technology accounts are set up on a prepay basis. Corporate Technology customers may decide to cancel their accounts at any time, however they will get a full refund of the service only if the cancellation request is made within 30 days of purchasing of the account, according to our <u>Money Back Guarantee Policy.</u></dd>
                                <dd>To cancel an account, the Customer needs to send an e-mail or support ticket to our Sales Department and provide any authentication credentials requested by Corporate Technology. Other forms of cancellation requests will not be accepted. Any incentives (free bonuses, etc.) offered to the Customer upon sign-up will be also cancelled.</dd>
                                <dd><i>Domain name registrations/transfers, service upgrades and web hosting plan renewals are treated as non-refundable services </i>(see below) and cannot be cancelled or refunded after being activated upon customer request. The Customer will have the opportunity to transfer his/her domain away to another host.</dd>
                                <dd>Late cancellations, as well as accounts cancelled by Corporate Technology due to TOS violations (where the customer has decided not to fix the violation) do not qualify for the 30-day money back guarantee.</dd>
                                <dd>All credit requests for VISA, MasterCard, American Express and Discovery payments are processed within 48 hours of the submission of the refund request (by e-mail or ticket, as mentioned above). The funds will be credited back to the Customer's credit/debit card and affect the Customer's credit/debit card balance in accordance with the credit/debit card issuer's terms of service.</dd>
                                <dd>All credit requests for PayPal payments are processed within 48 hours of the submission of the refund request (by e-mail or ticket, as mentioned above). The funds will be credited back to the Customer's PayPal account and affect the Customer's PayPal balance in accordance with PayPal's terms of service.</dd>
                                <dd><b>MONEY BACK GUARANTEE POLICY</b></dd>
                                <dd>Corporate Technology offers a 30-day money back guarantee. If you are not satisfied with our hosting services, you can submit a refund request in a trouble ticket opened from your web hosting control panel, or via email sent to billing@corporatetech.com, and receive a full refund for your hosting plan within 30 days of the purchase of the account. Please, NOTE that domain name registrations/transfers are non-refundable services (for more information see chapter 10 of this document).</dd>
                                <dd>SSL certificate issuance, as a third-party service, is not covered by the 30-day money-back guarantee that Corporate Technology provides for its web hosting services. An SSL certificate buyer is eligible for a refund within 7 days of purchase.</dd>
                                <dd>All recurring payments, as well as all payments made via a wire transfer, are non-refundable and are not covered by the 30-day money-back guarantee.</dd>
                                <dd><b>NON-REFUNDABLE SERVICES</b></dd>
                                <dd><b>Non-Refundable Services: </b><i>all service upgrades, wallet refills and plan renewal services purchased from the Web Hosting Control Panel; all 1-year or multi-year domain registration or transfer services purchased at sign-up or from the Web Hosting Control Panel.</i></dd>
                                <dd>All domain registrations and domain transfers are final, do not include any money-back guarantee and will be subject to a fee that is calculated on the basis of the actual NON-PROMOTIONAL annual price of the purchased domain's TLD, multiplied by the number of years of registration or transfer. Any refund amount credited back to the Customer in accordance with our 30-day money back guarantee will not include the cost of the non-refundable services (if any) that have been purchased with the Customer's web hosting plan upon sign-up (i.e. the Customer will be issued a partial refund on the original transaction if he/she has purchased non-refundable services within the web hosting account). A table containing the annual NON-PROMOTIONAL prices of all domain names offered by Corporate Technology is located in section 3 of this legal document.</dd>
                                <dd>CHARGEBACKS</dd>
                                <dd>Chargebacks are not considered as a way of refunding. Any Customer who initiates a credit card chargeback in response to a bill or order placed at Corporate Technology will be subject to full investigation.</dd>
                                <dd>When signing up for a web hosting account with Corporate Technology, customers agree to the conditions explicated in our Acceptable Use Policy (Corporate Technology) and the Web Hosting Terms of Service (this document). Thus, Corporate Technology, as well as our authorized retailers (PayPal, WorldPay, 2CheckOut), will resort to these documents as an evidence for the Customer's agreement, especially in the event that a Customer sends a chargeback inquiry based on points that he/or she first accepted, but is trying now to refute.</dd>
                                <dd>If/When a chargeback report is received, the first step of our Corporate Technology team will be to try to contact the user. Meanwhile, the user account will be suspended (in order to protect our system from fraudulent activity and misuse) and will remain suspended until the reasons for the chargeback have been clarified and decision has been taken for according action.</dd>
                                <dd>If the chargeback has occurred due to some misunderstanding, a reverse payment procedure will be initiated with our authorized retailers and the account will be re-activated.</dd>
                                <dd>If the user has sent a chargeback request based on groundless argumentation (to our own judgment and these Web Hosting Terms of Service), then a dispute will be started with our authorized retailers in which the Web Hosting Terms of Service (this document) and the Acceptable Use Policy of Corporate Technology will serve as proof of the Customer's agreement. The retailers then, taking into consideration all provisions in these legal documents, will decide whether the Customer has had sufficient grounds for the chargeback or Corporate Technology has the right to a reverse payment.</dd>
                                <dd>In case that the chargeback originates from a fraudulent user and no reverse payment procedure can be initiated, then the respective hosting account will remain suspended.</dd>
                            <dt id="wh_new_releases">11. NEWS RELEASES</dt>
                                <dd>Certain sections of Corporate Technology news releases may contain forward-looking statements projecting future events, such as new software incorporations, updates, promotions, hosting introductions, etc. It is possible that these statements may deviate from the actual circumstances, since they are treated as intentions and express expectations and approximate plans of action regarding the relevant forthcoming events.</dd>
                                <dd>Forward Looking Statements can be recognized by the availability of indicative words such as "believes", “anticipates," "plans," "may," "hopes," "can," "will," "expects," "is designed to," "with the intent," "potential", etc. However, their availability is not a prerequisite for a forward-looking statement to be treated as such.</dd>
                            <dt id="wh_torturous_conduct">12. TORTUROUS CONDUCT</dt>
                                <dd>Any way of indecent treatment towards a Corporate Technology employee will be considered as totally inappropriate and unacceptable. Each Customer is expected to communicate in an utterly polite and professional manner with Corporate Technology employees regarding any request for help or consultation, or any other reason for contacting our Corporate Technology team via email, chat, phone or through our Ticket Desk Support System. Each Customer is expected to avoid any form of emotional outburst or inappropriate language, or any other attempt to be undermined the importance of good ethics in communication and human relations. Any act of close nature, or other type of offensive, abusive, disrespectful or condescending behavior - oral, written, or delivered by a second party - to members of Corporate Technology customer care stuff will be interpreted as a breach of this TOS. Corporate Technology reserves the right to assess at its sole discretion which behavior is to be considered as a violation of the hereby stated rule and agreement, and to undertake according actions, including refuse refunds to users whose accounts have been suspended for violating this TOS provision.</dd>
                            <dt id="wh_force_majeure">13. FORCE MAJEURE CIRCUMSTANCES</dt>
                                <dd>Corporate Technology CANNOT and WILL NOT be liable for any failure or delay in doing its professional duties resulting from circumstances beyond its physical control. Such circumstances may represent acts of any governmental body, war, rebellion, sabotage, embargo, fire, flood, strike or other labor disturbances, interruption of or delay in transportation, unavailability of, interruption or delay in telecommunications or third party services (including DNS propagation), failure of third party software or hardware, or inability to obtain raw materials, supplies or power used in or equipment needed for provisioning of the Services.</dd>
                            <dt id="wh_service_provision">14. SERVICE PROVISION; WARRANTIES AND REPRESENTATIONS</dt>
                                <dd>You understand and agree that the Service is provided on an ‘AS-IS' basis without warranties or representations for its offering. The company bears no responsibility for connection problems in cases when customer's hosting with Corporate Technology confronts with service interruption issues. Corporate Technology assumes no liability for any service failure, or failure to deliver or store any Customer communication or personalization settings.</dd>
                            <dt id="wh_service_discontinuance">15. SERVICE DISCONTINUANCE</dt>
                                <dd>Corporate Technology reserves the right to discontinue the delivering of the services and terminate this agreement for a certain period of time or indefinitely, with a prior notice. If the reason for this is Customer's failure to observe these Web Hosting Terms of Service and/or our Acceptable Use Policy, no refund will be issued to the customer for the unused period of his/her annual subscription with Corporate Technology. If the Customer has not violated any part of these Web Hosting Terms of Service, the Acceptable Use Policy and/or any other related documents (Customer agreements) mentioned hereby - the Customer will receive partial refund covering the period of his/her web hosting subscription with Corporate Technology that has not been used.</dd>
                            <dt id="wh_complaints_procedure">16. COMPLAINTS PROCEDURE</dt>
                                <dd>Corporate Technology is dedicated to providing the highest levels of customer service round the clock. If you feel dissatisfied with any aspect of our service, you are encouraged to make use of our complaints procedure detailed below.</dd>
                                <dd>In order to file a complaint, please send us an email to complaints@duoservers.com, specifying the nature of the problem you are experiencing. Please, provide us with as many details about your particular case as possible. The more we know about the situation, the better, prompt and adequate our resolution will be.</dd>
                                <dd>Here are our steps of addressing every single complaint:</dd>
                                    <ol>
                                        <li>Corporate Technology will confirm receipt of your complaint in writing, notifying you that the complaint will be addressed without delay. Corporate Technology reserves the right to refuse to investigate complaints which are apparently frivolous, abusive or offensive.</li>
                                        <li>Corporate Technology customer care representatives will review your situation, search for an appropriate solution and inform you of the timeframe within which a response should be expected.</li>
                                        <li>If the Corporate Technology Customer Care representative is not able to resolve your case within the pre-announced period, your complaint will be escalated to a Customer Care manager.</li>
                                        <li>If the Corporate Technology Customer Care manager also can't provide you with an appropriate solution, then your complaint will be forwarded to the Company Director, who will further investigate the situation and ensure that all necessary measures have been taken. The Company Director will respond in writing within two working days, and reply with a solution within five working days.</li>
                                    </ol>
                            <dt id="wh_customer_service">17. CUSTOMER SERVICE CONTACT DETAILS</dt>
                                <dd><b>Customers can get in touch with our Customer Service via any of the contact options listed below:</b></dd>
                                    <ol>
                                        <li>Open a trouble ticket from the customer Web Hosting Control Panel (section Help Center - > Open New Ticket)</li>
                                        <li>Phone or Fax:</li>
                                            <a href="tel:71444129000"><p className="phone_link">US PHONE:   +1 (714) 441-2900</p></a>
                                        <li>Contact Us:</li>
                                            <p><a href="https://www.corporatetech.com/contact-us">Leave us a message</a></p>
                                    </ol>
                            <dt id="wh_revisions">18. REVISIONS</dt>
                                <dd>In order to improve its web hosting and customer care services, Corporate Technology reserves the right to revise these Terms of Services at any time without prior notice. Customers are encouraged therefore to review this document periodically.</dd>
                            <dt id="wh_assignment">19. ASSIGNMENT</dt>
                                <dd>Customers do not have the right to assign this Agreement without the explicit permission of Corporate Technology. This Agreement shall be in force and effect to the total benefit of the Customer and Corporate Technology, and their successors and permitted assigns.</dd>
                            <dt id="wh_termination">20. TERMINATION</dt>
                                <dd>You agree that Corporate Technology, at its sole discretion, may terminate your password, account or use of the Service (or any part thereof), and remove and discard any Content within the Service if you violate this Agreement.</dd>
                        </dl>
                    </div>
                </div>
                {/* <div className="back_to_top" id="to_top_btn" onClick={this.gotoTop}>
                    <i className='dnaicon dnaicon-chevron-up'/>
                </div> */}
            </div>
        )
    }
}
