import React, { Component } from 'react'
import { softdevData } from '../common/data.js'

export default class SoftdevServices extends Component {
    render() {
        return (
                <div id="key-services" className="wrapper f-center show-on-scroll inline-section">
                    <div className="content__icon f-center">
                        <img src="/imgs/icons/softdev.png" className="img-responsive pull-right" alt="softdev" />
                    </div>
                    <div>
                        <div>
                            <h1 className="key-services__title">Key Services Include</h1>
                        </div>
                        <ul className="key-services__ul">
                            {softdevData.map(ks => <li key={ks.keyservices} className={ks.icon}>
                            <span className="key-services__li">{ks.keyservices}</span></li>)}
                        </ul>
                    </div>
                </div>
        )
    }
}
