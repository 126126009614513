import React, {Component, Fragment} from "react";
import { withRouter } from "react-router-dom";
import Header from '../common/Header'
import Routes from '../routes'
import Footer from '../common/Footer'
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return  (
        <Fragment>
          <Header {...this.props}/>
            <Routes/>
          <Footer />
        </Fragment>
      )
    }
  }
  
  export default withRouter(ScrollToTop)