import React, { Component } from 'react'
import TermsTablePrice from '../../TermsTablePrice'



export default class VPS_Terms extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Virtual Private Servers Terms of Service</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Virtual Private Servers Terms of Service</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our VPS Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred to as 'Corporate Technology' in this document.</p>
                    <p className="terms__description--padding-small">When signing up for a VPS hosting account with Corporate Technology, the Customer agrees to abide by the rules and provisions explicated in these VPS Terms of Service, as well as all their coherent policies (Order Verification Policy, Acceptable Use Policy, etc.) mentioned herein.</p>
                    <p className="terms__description--padding-small">The Customer of our Services should also be aware that when signing up for a VPS hosting account with Corporate Technology, he/or she agrees to position data on our servers and make it accessible to the wide internet public. In this respect, the Customer understands and agrees that it is beyond the power of Corporate Technology to restrict any public access to this data and guarantee the security of the Customer's files. The Customer, therefore, is completely in charge of protecting his/her web content on our servers and should consider carefully each piece of information before uploading it to his/her newly allocated VPS server space. We highly recommend that Customers avoid publishing online personal data that could be abused.</p>

                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>

                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>I. SERVICE AGREEMENT; ENTIRE AGREEMENT</dt>
                                <dd>Corporate Technology shall provide its Services to You - the Customer - under the Terms of Services explicated in this Service Agreement. The Agreement is intended to keep the integrity of both Corporate Technology and the Customer and to protect both parties from liability. This Service Agreement will be enforced to ensure the speed, power, performance, and reliability of our Services.</dd>
                                <dd>The Service Agreement, together with the <a href="https://www.corporatetech.com/general-terms-order-verification">Order Verification Policy</a>, the <a href="https://www.corporatetech.com/virtual-private-acceptable-use">Acceptable Use Policy (AUP)</a>, and any other policies or agreements mentioned in this document, constitute the whole act of convention between Corporate Technology and the Customer.</dd>
                            <dt>II. VPS SERVER HOSTING SERVICES - FEATURES, RULES FOR ACTIVATION AND PAYMENT BASIS</dt>
                                <dd>Upon submitting of the online order form, the Customer agrees to abide by our Order Verification Policy and the following provisions:</dd>
                                    <ol>
                                        <li>The payment for the VPS service is set up on a monthly basis. The Customer agrees to pay for the rendered VPS hosting services in advance of each monthly service cycle.</li>
                                        <li>If the Customer fails to follow the monthly billing cycle, the rendered VPS hosting services will be suspended immediately. The data hosted on the VPS server will be kept intact for one month, after that it will be permanently erased.</li>
                                        <li>During a promotion campaign, a customer can purchase a maximum of 4 VPS packages, regardless of their type.</li>
                                        <li>The Customer agrees to observe the following VPS server usage limitations:</li>
                                            <ul>
                                                <li>Opened files: barrier 188; max. 206</li>
                                                <li>Rules in IP tables: max. 128</li>
                                                <li>Running processes: max. 2048</li>
                                                <li>TCP sockets: max. 360</li>
                                                <li>Ptty: max. 16</li>
                                            </ul>
                                        <li>All OpenVZ VPS packages in the Australian data center offer 2-Mbps bandwidth quotas.</li>
                                        <li>Setup fees and monthly service fees are non-refundable. The Customer agrees to a no-refund policy in advance.</li>
                                    </ol>
                            <dt>III. SERVICE GUARANTEES; DATA BACKUP</dt>
                                <ol>
                                    <li>SERVICE GUARANTEES</li>
                                        <p>Corporate Technology guarantees 99.9% network uptime and server stability. This, however, does not refer to problems stemming from:</p>
                                        <ol type="a">
                                            <li>Server Hardware Breakdown;</li>
                                            <li>User error(s) or purposeful interruption(s) of the user service (e.g. if the client shuts his/her own server down, Corporate Technology is not responsible for the downtime);</li>
                                            <li>Failure due to software that is not explicitly supported by Corporate Technology. If a hardware crash provoked by the Customer consequently happens, Corporate Technology is beyond the control and responsibility for the resulting downtime.</li>
                                        </ol>
                                        <p>For more information, please refer to our <a href="https://www.corporatetech.com/virtual-private-service-level">Service Level Guarantees.</a></p>
                                    <li>DATA BACKUP</li>
                                        <p>Due to the specificity of the VPS service, providing Customers with a fully independent and self-managed hosting environment, the DATA BACKUP of all Customer's website contents and databases is solely Customer's responsibility, as well.</p>
                                        <p>A weekly VPS backup option is available in the Managed Services package offered as an add-on administration service with each VPS plan. A weekly VPS backup is also available as a separate add-on service.</p>
                                </ol>
                            <dt>IV. SERVICE ACTIVATION AND CONTINUANCE TIMESCALES</dt>
                                <ol>
                                    <li>SERVICE ACTIVATION</li>
                                        <p><u><b>For newly signed up clients</b></u> – the services are activated immediately after our Sales Department gets in touch with the Customer over the phone to verify the payment details (this is required due to the high fraud activity spreading online, and in order to be prevented fraudulent account usage). The payment verification procedure is obligatory and if we fail to approve a transaction within 48 hours of the payment submission, the funds will be credited back to the payer and the order will be canceled.</p>
                                        <p><u><b>For existing customers</b></u> – the services for existing Customers, including upgrade services and renewals, are activated immediately after our Sales Department receives the according service payment.</p>
                                    <li>CONTINUANCE TIMESCALES</li>
                                        <p><u><b>VPS Accounts</b></u> – The newly-created VPS hosting accounts with Corporate Technology are valid for 1 month. They can be renewed on a monthly basis, i.e. for another month that will be added to the official expiration date of the VPS server account.</p>
                                        <p><u><b>Domain Name Registrations/Transfers</b></u> – Corporate Technology offers for registration/transfer all TLDs (Top-Level Domains) displayed in the table below. A domain name can be purchased either at sign up together with the VPS account (at a regular or PROMOTIONAL price) or separately (later on) as an upgrade from the Customer's Billing Manager. The Customer agrees to abide by Corporate Technology's Domain Name Registration Agreement and Domain Name Dispute Resolution Policy. The table below displays the minimum and maximum registration periods, as well as the annual NON-PROMOTIONAL prices of all TLDs:</p>
                                        <TermsTablePrice/>
                                        <p><u><b>Domain Renewals</b></u> – Domain names are valid for the period of their registration (domain names can be registered for up to 10 years, depending on the domain name's TLD extension). After the expiration of a domain name several stages follow regarding its renewal.</p>
                                            <ul>
                                                <li>The domain name <b>Redemption Grace Period (RGP)</b> is a registry level period of up to 30 days after a domain name's expiration date. During this period the domain name is kept on hold by the Registrar before it gets deleted. The domain registrant is allowed to retrieve the domain name from deletion, but this process costs additional redemption fee. This extra 30-day period extends the time available for renewing domain names. However, all domains that enter a redemption period are removed from the zone files (the list of domain names currently in the global DNS), therefore any web site or e-mail services associated with the domain name will stop working.</li>
                                                <li><b>Extended Redemption Grace Period</b> – the extended redemption period adds another 7 – 30 days to the domain name's redemption period. During this period the domain name owner is allowed to retrieve the domain name from deletion by contacting the domain's registrar.</li>
                                                <li>After the redemption period of an expired domain name is over, follows the <b>Pending Delete Period</b> – a registry level period of up to 5 days during which no one is allowed to renew the domain name and it cannot be newly registered because it's still not returned to the public pool of domain names.</li>
                                                <li><b>Released by Registry Period</b> – after the domain name's ‘Pending Delete Period' is over, the domain name is released back into the public pool of domain names and is newly available for registration.</li>
                                            </ul>
                                        <p>We strongly recommend that Customers renew their domain registrations before their domain names are placed into redemption period. After a domain name transits in the redemption period, we are not able to prevent its zone files from being removed from the DNS system, and any web site and associated services will cease working.</p>
                                        <p>We can assist Customers in retrieving their domain names only if the domain names were registered with us. Customers will be charged a REDEMPTION FEE of $270.00 USD for each domain registration renewal. If the domain name was registered with another registrar, the Customer needs to contact that registrar for more information regarding its retrieving.</p>
                                </ol>
                            <dt>V. CANCELLATION, REFUND & MONEY-BACK; CHARGEBACKS</dt>
                                <ol>
                                    <li>CANCELLATIONS</li>
                                        <p>In order to cancel a VPS account, the Customer must send a support ticket to Corporate Technology's Sales Department via his/her Billing Manager and provide any authentication credentials requested by Corporate Technology. Any other form of cancellation request will not be accepted.</p>
                                    <li>REFUNDS</li>
                                        <p><u><b>VPS accounts</b></u> – The VPS accounts are set up on a monthly pre-pay basis. Customers may cancel their accounts at any time, however they will not be entitled to a refund for the unused period of the prepaid month, since these services do NOT include Money Back Guarantee. Any incentives offered to the Customers upon sign-up will be also canceled.</p>
                                        <p><u><b>Domain Name Registrations/Transfers</b></u> – Domain name registrations/transfers are also treated as non-refundable services and cannot be canceled or refunded after activated upon Customer request. The domain name owner will have access to a domain management account, and will be able to transfer the domain name to another registrar.</p>
                                    <li>CHARGEBACKS</li>
                                        <p>Chargebacks are not considered as a way of refunding. Any Customer who initiates a credit card chargeback in response to a bill or order placed with Corporate Technology will be subject to full investigation.</p>
                                        <p>When signing up for a VPS account with Corporate Technology, the Customer agrees to the Terms of Services explicated in this document (and its coherent policies). Thus, Corporate Technology, as well as Corporate Technology's authorized retailers (PayPal, WorldPay, 2CheckOut), will resort to these documents as an evidence for the Customer's agreement, especially in the event that a Customer sends a chargeback inquiry based on points that he/or she first accepted, but is trying now to refute.</p>
                                        <p>If/When a chargeback report is received, the first step of Corporate Technology's Customer Care team will be to try to contact the user. Meanwhile, the Customer's VPS account will be suspended (in order to protect our system from fraudulent activity and misuse) and will remain suspended until the reasons for the chargeback have been clarified and a decision has been taken for according action.</p>
                                        <p>If the Customer has sent a chargeback request based on groundless argumentations (according to Corporate Technology's own judgment and these VPS Terms of Service), then a dispute will be started with the authorized retailers in which the VPS Terms of Service and the AUP of Corporate Technology will serve as evidence of the Customer's agreement. The retailers then, taking into consideration all provisions in these legal documents, will decide whether the Customer has had sufficient grounds for the chargeback or Corporate Technology has the right to a reverse payment.</p>
                                        <p>In case that the chargeback originates from a fraudulent user (end-client) and no reverse payment procedure can be activated, then the respective VPS account will remain suspended and a $30 USD fee will be withdrawn from the commission of the reseller (the client of our Free Reseller Hosting Program) whose client's account (the end-client) has been the reason for the chargeback (in order to be covered the fees imposed to Corporate Technology from our authorized retailers for the claimed unauthorized transaction).</p>
                                </ol>
                            <dt>VI. VPS RESELLER TOOLS</dt>
                                <dd>An eNom domain reseller account is available as a free bonus with all VPS server packages. A free billing account is included in the Ultra VPS plan. The VPS reseller tools are installed per user's request.</dd>
                            <dt>VII. CUSTOMER RESPONSIBILITIES</dt>
                                <ol>
                                    <li>ACCOUNT REGISTRATION AND MANAGEMENT</li>
                                    <p>In consideration of their use of the Service, Customers agree to:</p>
                                        <ul>
                                            <li>Provide <a href="https://www.corporatetech.com/general-terms-privacy-policy">true</a>, <a href="https://www.corporatetech.com/general-terms-privacy-policy">accurate</a>, <a href="https://www.corporatetech.com/general-terms-privacy-policy">current</a> and <a href="https://www.corporatetech.com/general-terms-privacy-policy">complete</a> information about themselves as prompted by the registration form of the Service. Maintain and promptly update their registration data to keep it true, accurate, current and complete. If the Customer provides any information that is untrue, inaccurate, not current or incomplete, or Corporate Technology has reasonable grounds (at its sole discretion) to suspect that such information is untrue, inaccurate, not current or incomplete, Corporate Technology has the right to suspend or terminate the Customer's account and refuse any and all current or future use of the Service (or any portion thereof). If untrue details are used in order to avoid VAT the customer can be held responsible by the respective national tax collecting agency.</li>
                                            <li>When introducing any changes to their contact details, Customers are obliged to notify Corporate Technology in the course of 10 business days after the changes become valid. The introduction of changes should be done online through the customers' VPS Billing Manager, not via email.</li>
                                            <li>Customers will receive a password and account designation upon completing the registration process of the Service. Customers are responsible for keeping the confidentiality of their passwords and accounts, and are fully responsible for all activities that occur under that password or account. Customers agree to: a) immediately notify Corporate Technology about any unauthorized use of their password or account or any other breach of security; b) ensure that they safely exit (log out from) their account at the end of each session. Corporate Technology cannot and will not be liable for any loss or damage of account information arising from Customer's failure to comply with this provision.</li>
                                            <li>If a VPS hosting account is registered with details different than those of the person who paid for the account, and in the case of any dispute, Corporate Technology will take the side of the person who paid for the account and shall not provide access to any other person, since – a hosting account owner is and will be considered to be the credit card /PayPal account holder.</li>
                                        </ul>
                                    <li>SERVER USAGE</li>
                                        <ul>
                                            <li><b>CPU Usage</b> (or usage of the Central Processing Unit(s) of the web server the Customer's account is hosted on) - the CPU usage is subject to some regulations. VPS hosting accounts can exploit proportional shares of the main server's CPU cores. Corporate Technology explicitly recommends that Customers be very judicious when using the CPU power for their accounts. We reserve the right to block (suspend) accounts that excessively use CPU. If such measures are taken, we will notify the Customer and give him/her an opportunity to upgrade to a different VPS package or a dedicated server hosting solution.</li>
                                            <li><b>Guaranteed RAM </b>– Each VPS hosting account has guaranteed RAM that it's able to use. This RAM is available at all times to the VPS server.</li>
                                            <li><b>Memory Latency </b>– The memory latency of a VPS server cannot exceed 1000 cycles for a period of more than 10 seconds. We reserve the right to block (suspend) accounts that generate a memory latency of 1000 cycles or more for more than 10 seconds. If such measures are about to be taken, we will notify the Customer and give him/her an opportunity to upgrade to a dedicated server hosting solution.</li>
                                            <li><b>IP Address Allocation </b>– Each VPS hosting account comes with a Dedicated IP Address. The Customer agrees to add new IP addresses to his server only via Corporate Technology's upgrade table.</li>
                                        </ul>
                                </ol>
                            <dt>VIII. NO UNLAWFUL OR PROHIBITED USE</dt>
                                <ol>
                                    <li>SERVER USAGE</li>
                                        <p>Corporate Technology expressly bans the use of any VPS server hardware or software for trying to illegally access another hosted machine or network. Customers may not attempt to gain unauthorized access to any of Corporate Technology's Services, other accounts, computer systems or networks connected to any of Corporate Technology's Services, through hacking, password mining or other means of close nature. Customers may not obtain or attempt to obtain any materials or information through any means not intentionally made available by Corporate Technology's Services. For more detailed information regarding all prohibited uses of our server network, please closely review the next chapter of these Terms and our AUP (Acceptable Use Policy). If you still have inquiries over the matter, feel free to contact any of our Customer Care representatives.</p>
                                    <li>HOSTED CONTENT</li>
                                        <p>Server content MUST comply with the parameters stated in this TOS and our Acceptable Use Policy. Web content requirements exclude the publication of illegal content or content considered inappropriate otherwise. If any of Corporate Technology's Services are used for the illegal purpose of SPAM or UCE, they will be instantly terminated. Customers are solely responsible for the content of their messages. Using Corporate Technology's Services in connection with surveys, contests, pyramid schemes, chain letters, junk e-mail, spamming or any duplicative or unsolicited messages (commercial or other) is prohibited. In this light, Corporate Technology expressly forbids any breach of Federal, State or Local regulation, including, but not limited to, the transmission of: copyrighted material, material legally judged to be threatening or obscene, and material protected by trade secrets. Corporate Technology's VPS servers CANNOT be used for the propagation, distribution, housing, processing, storing, or handling in any way of lewd, obscene, adult or pornographic material, or any other material which is considered impermissible by Corporate Technology's VPS Terms of Service (this document) and the Acceptable Use Policy (AUP).</p>
                                    <li>CUSTOMER AGREEMENT</li>
                                        <p>As a condition for Customer use of Corporate Technology's Services, you – the Customer, agree that you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices, and our Acceptable Use Policy (AUP). Corporate Technology's VPS servers can be used explicitly and only for legal purposes, and each attempt for taking indecent advantage of the servers, inconsistent with the rules, will be immediately counteracted. Corporate Technology reserves the right at any time to disclose any information, as Corporate Technology deems necessary, to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove from the Services any information or materials, in whole or in part, in the sole discretion of Corporate Technology.</p>
                                </ol>
                            <dt>IX. E-COMMERCE CUSTOMER WEBSITES</dt>
                                <dd>When buying a VPS server for the purpose of creating an e-commerce online store or related e-commerce activities website, the Customer agrees to be fully responsible for the development, operation and maintenance of his/her website/online store and e-commerce activities within that website. The Customer is the only one in charge of approving, processing and filling client orders and taking care of client requests or complaints. The Customer is also the only one in charge of the payment or satisfaction of any and all taxes related to his/her web site and online store. The Customer bears the responsibility for ensuring the security and confidentiality of any clients' data (including, without limitation, customer credit card numbers) that he/she may receive in connection with his/her web site or online store.</dd>
                                <dd>Each Customer is in charge of all products and services, as well as all contents and materials appearing online, including:</dd>
                                    <ul>
                                        <li>the accuracy and relevance of Customer Content, and content and material showcased in the Customer store or on its products;</li>
                                        <li>certainty that the Customer Content and content and materials appearing in the Customer store or on its products do not violate or infringe upon the rights of any person;</li>
                                        <li>certainty that the Customer Content and the content and materials appearing in the Customer store or on its products are not calumnious or somehow illegal.</li>
                                    </ul>
                                <dd>Each Customer guarantees to Corporate Technology that he/she owns the right to use the Customer Content and its material, including all text, graphics, sound, music, video, programming, scripts and applets. Each Customer warrants to Corporate Technology that the use, reproduction, propagation and transmission of the Customer Content and any information and materials contained inside do not, and will not:</dd>
                                    <ul>
                                        <li>infringe or abuse any copyright, patent, trademark, trade secret or any other proprietary right of a third party;</li>
                                        <li>break any criminal law or manifest false advertising, unfair competition, vilification, privacy intrusion; violate a right of publicity or violate any other law or regulation.</li>
                                    </ul>
                                <dd>Each Customer empowers Corporate Technology to reproduce, copy, use and propagate all and any part of the Customer Content for ensuring and operating the Services. Corporate Technology is granted the right to estimate, at its own discretion, the extent to which these operations may be carried out.</dd>
                            <dt>X. REFUSAL OF SERVICE </dt>
                                <ol>
                                    <li>SERVICE DENIAL</li>
                                        <p>Corporate Technology will thoroughly scrutinize all received orders and decide, to its own discretion, which of them do not comply with the officially stated requirements for use of Corporate Technology's VPS servers. Corporate Technology reserves the right to refuse Service to unlawful parties.</p>
                                    <li>SERVICE DISCONTINUANCE</li>
                                        <p>Corporate Technology reserves the right to discontinue, at its own discretion, the Service provision to any subscriber that has violated the VPS Terms of Service and/or the VPS Acceptable Use Policy. In case that the Service provision has been discontinued by Corporate Technology with no explicitly stated reason, the customer will be refunded the last payment for their VPS server.</p>
                                </ol>
                            <dt>XI. MISCELLANEOUS</dt>
                                <ol>
                                    <li>NOTICES</li>
                                        <p>Notices, including notices concerning breach of this Agreement will be sent either to the email address provided by the Customer at sign up, OR mailed by first class mail to the postal address that the Customer has on file with Corporate Technology. In both cases, delivery shall be deemed to have been made five (5) days after the notice has been sent. Notices from the Customer to Corporate Technology should be sent to the address provided on Corporate Technology's website.</p>
                                    <li>COMPLAINTS PROCEDURE</li>
                                        <p>Corporate Technology guarantees the highest level of Customer Care service round the clock. If you feel dissatisfied with any aspect of the Service, feel free to make use of our complaints procedure detailed below.</p>
                                        <p>In order to file a complaint, please send us an email to complaints@duoservers.com, specifying the nature of the problem you are experiencing. Please, provide as many details about your particular case as possible. The more we know about the situation, the better, prompt and adequate our resolution will be.</p>
                                        <p>Here are our steps of addressing every single complaint:</p>
                                            <ul>
                                                <li>Corporate Technology will confirm receipt of your complaint in writing, confirming that the complaint will be addressed without delay. Corporate Technology reserves the right to refuse to investigate a complaint that is obviously frivolous, abusive or offensive.</li>
                                                <li>A Corporate Technology's Customer Care representative will review your situation, look for an appropriate solution and inform you of the timeframe within which a response should be expected.</li>
                                                <li>If Corporate Technology's Customer Care representative can't resolve your complaint within the pre-announced period, your complaint will be escalated to a Customer Care manager.</li>
                                                <li>If the Customer Care manager can't resolve your complaint, as well, it will be forwarded to the Company Director, who will investigate the case to ensure that all necessary measures have been taken and will respond in writing within two working days, and reply with a solution within five working days.</li>
                                            </ul>
                                    <li>TORTUROUS CONDUCT</li>
                                        <p>Any way of indecent treatment towards a Corporate Technology's Customer Care employee will be considered as totally inappropriate and unacceptable. Each Customer is expected to communicate in an utterly polite and professional manner with Corporate Technology's employees regarding any request for help or consultation via email, chat, phone or through our Ticket Desk Support System. Each Customer is expected to avoid any form of emotional outburst or inappropriate language, or any other attempt to undermine the importance of good ethics in communication and human relations. Any act of close nature, or other type of offensive, abusive, disrespectful or condescending behavior – oral, written, or delivered by a second party – to members of Corporate Technology's Customer Care stuff will be interpreted as a breach of this TOS. Corporate Technology reserves the right to assess at its sole discretion which behavior is to be considered as a violation of the hereby stated rule and agreement, and to undertake according actions.</p>
                                    <li>CUSTOMER CARE SERVICE</li>
                                        <p><u><b>Technical Support</b></u></p>
                                        <p>Due to the specificity of the Service, Customers are fully in charge of the management and maintenance of their VPS. However, if they need assistance or want to leave this task to our highly-qualified administrators department, they can avail of our Managed Support service available at a monthly fee of $15 USD.</p>
                                        <p><u><b>Control Panel Migration procedures</b></u></p>
                                        <p>If the Customer wants to switch their VPS Control Panel installation from cPanel to DirectAdmin or vice versa, then they will need to follow a pre-defined Control Panel migration procedure. This procedure applies only to Regular VPS and Ultra VPS accounts ordered with an installation of the CentOS operating system and a Control Panel (cPanel or DirectAdmin) setup.</p>
                                            <ol>
                                                <li>The Customer needs to purchase a new VPS server with an installation of the Control Panel (cPanel or DirectAdmin), which they want to migrate to.</li>
                                                    <ul>
                                                        <li>If the Customer requests a migration of their current VPS server within 7 days of the last subscription payment date - all the current main service and upgrade payments will be cancelled and refunded. All recurring contracts associated with this VPS account will be canceled too. The VPS server itself will be terminated within 24 hours after the new VPS has been set up and its corresponding account will be closed.</li>
                                                        <li>If the migration procedure is requested more than 7 days after the last transaction, Corporate Technology will cancel all recurring contracts associated with the current VPS account, but no payments will be refunded.
                                                            <br></br>Once all the data has been migrated to the new VPS server, the current VPS server subscription should be left to expire, and the client can use it during this period.
                                                        </li>
                                                    </ul>
                                                <li>The migration procedure can be carried out by the Customer following the instructions provided by Corporate Technology.</li>
                                                <li>Upon customer's request the procedure can be accomplished by Corporate Technology. In the event of a problem during the migration procedure, Corporate Technology will terminate the process. In such cases, the Customer agrees to cover a fee of $ 15.00 USD prior to the completion of the procedure.</li>
                                                <li>In case that the migration is terminated by Corporate Technology, the Customer can complete the process by themselves without paying any additional fees.</li>
                                            </ol>
                                        <p><u><b>Pre-sale and general questions</b></u></p>
                                        <p>Customers may use any of the support options listed below:</p>
                                            <ul>
                                                <li>Open a trouble ticket from the Customer's VPS Billing Manager. 1-hour support response is guaranteed!</li>
                                                <li>Phone us at:  US Phone +1 (714) 441-2900</li>
                                                <li>Use our Live Chat</li>
                                                <li>E-mail us using our <a href="https://www.corporatetech.com/contact-us">Contact Us</a></li>
                                            </ul>
                                </ol>
                            <dt>XII. GENERAL REGULATIONS</dt>
                                <ol>
                                    <li>GOVERNING LAW</li>
                                        <p>This Agreement shall be governed by and construed in accordance with the laws of England.</p>
                                    <li>ARBITRATION</li>
                                        <p>Any controversy or claim arising out of or relating to this Agreement, or any breach of this Agreement, in excess of $250.00 USD will be settled in accordance with the commercial arbitration rules of the American Arbitration Association.</p>
                                    <li>ENFORCEMENT</li>
                                        <p>Failure to enforce any of the provisions of this Agreement shall in no way be considered to be a waiver of these provisions, or to affect in any way the validity of this Agreement. If one or more of the provisions contained in this Agreement are found to be invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</p>
                                    <li>INDEMNIFICATION</li>
                                        <p>The Customer agrees to indemnify Corporate Technology, its management body and employees (each an "Indemnified Party") against any losses, claims, damages, liabilities, penalties, court actions, proceedings or judgments (collectively, "Losses") to which the Indemnified Party may become subject, and out of which Losses arise or relate to this Agreement or the Customer's use of the Services. The Customer agrees to reimburse the Indemnified Party for all legal and other expenses, including any attorney fees paid by the Indemnified Party in connection with investigating, defending or settling any Loss whether or not in connection with pending or threatened litigation in which the Indemnified Party is a party.</p>
                                    <li>LIMITATION ON Corporate Technology's LIABILITY</li>
                                        <p><u><b>Force Majeure Circumstances </b></u>– Corporate Technology shall not be liable for any failure in the provision of the Services to the Customer resulting, directly or indirectly, from any (i) weather conditions and natural disasters, (ii) actions of any governmental or military authority, (iii) failure caused by telecommunications or Internet provider, or (iv)other force or occurrence beyond its control.</p>
                                        <p><u><b>Damages & Losses </b></u>– Corporate Technology shall not be liable for (i) any indirect, incidental, special or consequential damages, OR for any loss of profits or loss of revenues resulting from the use of Corporate Technology's Services by the Customer or any third parties, or (ii) any loss of data resulting from delays, non-deliveries or service interruptions.</p>
                                        <p><u><b>Service Provision </b></u>– Corporate Technology provides the Services on an “AS IS” basis, without any warranties, including but not limited to, implied warranties, merchantability, or fitness of the Services for any particular purpose. The Customer shall be solely responsible for the selection, use and suitability of the Services, and Corporate Technology shall have no liability therefore.</p>
                                    <li>ASSIGNMENT</li>
                                        <p>Customers do not have the right to assign this Agreement without the explicit permission of Corporate Technology. This Agreement shall be in force and effect to the total benefit of the Customer and Corporate Technology and their successors and permitted assigns.</p>
                                    <li>REVISIONS</li>
                                        <p>Corporate Technology reserves the right to revise this document at any time, without prior notice. Customers are encouraged therefore to periodically review these VPS Terms of Service and the Acceptable Use Policy.</p>
                                </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
