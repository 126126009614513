import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { pageContact } from '../common/data'

export default class PageContact extends Component {
    render() {
        const { audit, pdf_file } = pageContact
        return (
            <div className="page-contact">
                    <div className="page-contact--img" data-aos-anchor-placement="center-bottom" data-aos="fade-right" />
                    <div data-aos-anchor-placement="center-bottom" data-aos="fade-left" className="page-contact--actions">
                    <Link to="/contact-us" className={`action--contact ${audit}`} />
                        <a href={`${pdf_file}`} className='action--download' rel="noopener noreferrer" target='_blank' download>
                        </a>
                    </div>
            </div>
        )
    }
}