import React, { useEffect } from 'react'



function HeroSecurity(props) {

    useEffect(() => {
        const counters = document.querySelectorAll('.counter');
        
        counters.forEach(counter => {
        const speed = counter.getAttribute('speed'); // The lower the slower
        const updateCount = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.innerText;
    
            // Lower inc to slow and higher to slow
            const inc = target / speed;
    
            // console.log(inc);
            // console.log(count);
    
            // Check if target is reached
            if (count < target) {
                // Add inc to count and output in counter
                counter.innerText = count + inc;
                // Call function every ms
                setTimeout(updateCount, 30);
            } else {
                counter.innerText = target;
            }
        };
    
        updateCount();
    });
    }, [])

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="security__bg">
                <div className="header__wrapper">
                    <div className="services__title fromLeft">{props.header}</div>
                    <div className="services__data fromLeft">
                        <span class="counter" speed={props.data1} data-target={props.data1}><h1>0</h1></span><span>%</span>
                        <span className="services__data--small fromLeft">{props.p1}
                    </span></div>
                    <div className="services__data fromLeft">
                        <span>$</span><span class="counter" speed='100' data-target={props.data2}><h1>0</h1></span><span>,000</span>
                        <span className="services__data--small fromLeft">{props.p2}
                    </span></div>
                    <div className="services__data fromLeft">
                        <span class="counter" speed={props.data3} data-target={props.data3}><h1>0</h1></span><span>%</span>
                        <span className="services__data--small fromLeft">{props.p3}
                    </span></div>
                </div>
            </div>
        </div>
    )

}

export default HeroSecurity