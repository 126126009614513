import React, { Component } from 'react'
import TermsTablePrice from '../../TermsTablePrice'



export default class DS_DedicatedServer extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Dedicated Servers Terms of Service</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology Dedicated Servers Terms of Service</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our Dedicated Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred to as 'Corporate Technology' in this document.</p>
                    <p className="terms__description--padding-small">When signing up for a Dedicated server hosting account with Corporate Technology, the Customer agrees to abide by the rules and provisions explicated in these Dedicated Terms of Service, as well as all their coherent policies (Order Verification Policy, Acceptable Use Policy, etc.) mentioned herein.</p>
                    <p className="terms__description--padding-small">The Customer of our Services should also be aware that when signing up for a Dedicated server hosting account with Corporate Technology, he/or she agrees to position data on our servers and make it accessible to the wide internet public. In this respect, the Customer understands and agrees that it is beyond the power of Corporate Technology to restrict any public access to this data and guarantee the security of the Customer's files. The Customer, therefore, is completely in charge of protecting his/her web content on our Dedicated servers and should consider carefully each piece of information before uploading it to his/her newly allocated Dedicated server space. We highly recommend that Customers avoid publishing online personal data that could be abused.</p>

                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>I. SERVICE AGREEMENT; ENTIRE AGREEMENT</dt>
                                <dd>Corporate Technology shall provide its Services to You - the Customer - under the Terms of Services explicated in this Service Agreement. The Agreement is intended to keep the integrity of both Corporate Technology and the Customer and to protect both parties from liability. This Service Agreement will be enforced to ensure the speed, power, performance, and reliability of our Services.</dd>
                                <dd>The Service Agreement, together with the Order Form, the <a href="https://www.corporatetech.com/dedicated-server-acceptable-use-policy">Acceptable Use Policy (AUP)</a>, and any other policies or agreements mentioned in this document, constitute the whole act of convention between Corporate Technology and the Customer.</dd>
                            <dt>II. RULES FOR ACTIVATION AND MONTHLY PAYMENT BASIS OF THE DEDICATED SERVER HOSTING SERVICE</dt>
                                <dd>Upon submitting the online order form, the Customer agrees to abide by our Order Verification Policy and the following provisions:</dd>
                                    <ol type="1">
                                        <li>The payment of the Dedicated server hosting service is set up on a monthly basis. The Customer agrees to pay for the rendered Dedicated server hosting services in advance for each monthly service term.</li>
                                        <li>Non-Payment of the service shall result in immediate service disconnection. Customers failing to secure payment by the time of the due date will incur service interruption. The data hosted on the Dedicated server will be kept for one month, after that it will be permanently deleted.</li>
                                        <li>Setup fees and monthly service fees are non-refundable. The Customer agrees to a no-refund policy in advance.</li>
                                    </ol>
                            <dt>III. RULES FOR ACTIVATION AND MONTHLY PAYMENT BASIS OF THE DEDICATED SERVER HOSTING SERVICE</dt>
                                <ol type="1">
                                    <li><b>SERVICE GUARANTEES</b></li>
                                        <p>Corporate Technology guarantees 99.9% network uptime and server stability. This, however, does not refer to problems stemming from:</p>
                                            <ol type="1">
                                                <li>Server Hardware Breakdown;</li>
                                                <li>User error(s) or purposeful interruption(s) of the user service (e.g. if the client shuts his/her server down, Corporate Technology is not responsible for the downtime);</li>
                                                <li>Failure due to software that is not explicitly supported by Corporate Technology. If a hardware crash provoked by the Customer consequently happens, Corporate Technology is beyond the control and responsibility for the resulting downtime.</li>
                                            </ol>
                                        <p>For more information, please refer to our <a href="https://www.corporatetech.com/dedicated-server-service-guarantee">Service Level Guarantees.</a></p>
                                    <li><b>DATA BACKUP</b></li>
                                        <p>Each Customer will get a default backup space quota of 100 GB, which they can avail of at any given moment.</p>
                                        <p>Additionally, each Google Drive and/or Dropbox account owner is entitled to another 100 GB of backup space.</p>
                                        <p>A 50 GB backup storage option is available as part of our Managed Services package as well.</p>
                                </ol>
                            <dt>IV. SERVICE ACTIVATION AND CONTINUANCE TIMESCALES</dt>
                                <ol type="1">
                                    <li>SERVICE ACTIVATION</li>
                                        <p>For newly signed up clients - the Services are activated only after our Sales Department gets in touch with the Customer over the phone to verify the payment details (this is required due to the high fraud activity spreading online, and to prevent fraudulent account usage). The payment verification procedure is obligatory and if we fail to approve a transaction within 48 hours of the payment submission, the funds will be credited back to the payer and the order will be canceled.</p>
                                        <p>For existing customers - the services for existing Customers, including upgrade services and renewals, are activated immediately after our Sales Department receives the according service payment.</p>
                                    <li>CONTINUANCE TIMESCALES</li>
                                        <p>Domain Name Registrations/Transfers - Corporate Technology offers for registration/transfer all TLDs (Top-Level Domains) displayed in the table below. A domain name can be purchased either at sign up together with the Dedicated server hosting account (at a regular or PROMOTIONAL price) or separately (later on) as an upgrade from the Customer's Dedicated management account. The Customer agrees to abide by Corporate Technology's Domain Name Registration Agreement and Domain Name Dispute Resolution Policy. The table below displays the minimum and maximum registration periods, as well as the annual NON-PROMOTIONAL prices of all TLDs:</p>
                                        <TermsTablePrice/>
                                        <p>Domain Renewals - Even though Corporate Technology will provide regular reminders about the expiration of the domain name(s) registered through the company, it is your obligation to keep track of when the registration service(s) will expire. Should you fail to renew a domain name before its expiration date, the domain name will be suspended or canceled.</p>
                                        <ol type="1">
                                            <li>After a domain name’s expiration date, there is a period of 30 days, in which the domain name can be renewed. Certain domain name extensions do not have such a period, so leaving a domain name to expire before attempting a renewal is at your discretion.</li>
                                            <li>After the 30-day renewal period has passed, some domain names (as per TLD Registry Policies) enter a so-called Redemption Grace Period. A domain name that has entered a Redemption Grace Period can still be renewed. However, additional fees will be added to the domain's renewal price. You can find the official information regarding the Redemption Grace Period on ICANN’s page: http://www.icann.org/registrars/redemption-proposal-14feb02.htm.If a Redemption Grace Period is applicable for a specific domain name, it will last between 30 and 42 days.</li>
                                            <li>After the Redemption Grace Period (or the renewal period – if RGP is not applicable) has passed, the domain name(s) can be: provided to third parties; Corporate Technology may choose to auction the domain name(s); Corporate Technology may return them in the public registration pool. All of these actions can be carried out at any time and without your consent.</li>
                                        </ol>
                                        <p>We strongly recommend that Customers renew their domain registrations before their domain names are placed into the redemption period. After a domain name transits in the redemption period, we are not able to prevent its zone files from being removed from the DNS system, and any web site and associated services will cease working.</p>
                                        <p>We can assist Customers in retrieving their domain names only if the domain names were registered with us. Customers will be charged a REDEMPTION FEE of USD 270.00 for each domain registration renewal. If the domain name was registered with another registrar, the Customer needs to contact that registrar for more information regarding its retrieving.</p>
                                </ol>
                            <dt>V. CANCELLATION, REFUND & MONEY-BACK GUARANTEE, AND CHARGEBACKS</dt>
                                <ol type="1">
                                    <li><b>CANCELLATIONS</b></li>
                                        <p>To cancel a Dedicated server account, the Customer must send a support ticket to Corporate Technology's Sales Department via his/her Dedicated management account and provide any authentication credentials requested by Corporate Technology. Any other form of cancellation request will not be accepted.</p>
                                    <li><b>REFUNDS</b></li>
                                        <p>Dedicated server hosting accounts - The Dedicated server accounts are set up on a monthly prepay basis. Customers may cancel their accounts at any time, however, they will not be entitled to a refund for the unused period of the prepaid month, since these services do NOT include Money Back Guarantee. Any incentives offered to the Customers upon sign-up will be also canceled.</p>
                                        <p>Domain Name Registrations/Transfers - Domain name registrations/transfers are also treated as non-refundable services and cannot be canceled or refunded after activated upon Customer request. The domain name owner will have access to a domain management account and will be able to transfer the domain name to another registrar.</p>
                                    <li><b>CHARGEBACKS</b></li>
                                        <p>Chargebacks are not considered as a way of refunding. Any Customer who initiates a credit card chargeback in response to a bill or order placed with Corporate Technology will be subject to full investigation.</p>
                                        <p>When signing up for a Dedicated server hosting account with Corporate Technology, the Customer agrees to the Terms of Services explicated in this document (and its coherent policies). Thus, Corporate Technology, as well as Corporate Technology's authorized retailers (PayPal, WorldPay, 2CheckOut), will resort to these documents as an evidence for the Customer's agreement, especially if a Customer sends a chargeback inquiry based on points that he/or she first accepted, but is trying now to refute.</p>
                                        <p>If/When a chargeback report is received, the first step of Corporate Technology's Customer Care team will be to try to contact the Customer. Meanwhile, the Customer's Dedicated server account will be suspended (to protect our system from fraudulent activity and misuse) and will remain suspended until the reasons for the chargeback have been clarified and a decision has been taken for according action.</p>
                                        <p>If the chargeback has occurred due to some misunderstanding, a reverse payment procedure will be initiated with the authorized retailers, and eventually, the Dedicated server account will be re-activated.</p>
                                        <p>In case that the chargeback originates from a fraudulent user (end-client) and no reverse payment procedure can be activated, then the respective Dedicated server account will remain suspended and a $30 USD fee will be withdrawn from the commission of the reseller (the client of our Free Reseller Hosting Program) whose client's account (the end-client) has been the reason for the chargeback (to be covered the fees imposed to Corporate Technology from our authorized retailers for the claimed unauthorized transaction).</p>
                                </ol>
                            <dt>VI. HOSTING RESELLER STORES</dt>
                                <dd>A collection of free hosting reseller tools: a free billing software (ClientExec) and a free domain reseller account are available with each Dedicated Server hosting package. All bonuses are given to the customer’s request.</dd>
                            <dt>VII. CUSTOMER RESPONSIBILITIES: ACCOUNT REGISTRATION AND MANAGEMENT</dt>
                                <dd>In consideration of their use of the Service, Customers agree to:</dd>
                                    <ul>
                                        <li>Provide true, accurate, current, and complete information about themselves as prompted by the registration form of the Service. Maintain and promptly update their registration data to keep it true, accurate, current, and complete. If the Customer provides any information that is untrue, inaccurate, not current or incomplete, or Corporate Technology has reasonable grounds (at its sole discretion) to suspect that such information is untrue, inaccurate, not current or incomplete, Corporate Technology has the right to suspend or terminate the Customer's account and refuse all current or future use of the Service (or any portion thereof). If untrue details are used to avoid VAT the customer can be held responsible by the respective national tax collecting agency.</li>
                                        <li>When introducing any changes to their contact details, Customers are obliged to notify Corporate Technology in 10 business days after the changes become valid. The introduction of changes should be done online through the customers' Dedicated Management Account, not via e-mail.</li>
                                        <li>Customers will receive a password and account designation upon completing the registration process of the Service. Customers are responsible for keeping the confidentiality of their passwords and accounts and are fully responsible for all activities that occur under that password or account. Customers agree to:</li>
                                            <ol>
                                                <li>immediately notify Corporate Technology about any unauthorized use of their password or account or any other breach of security; ensure that they safely exit (log out from) their account at the end of each session.</li>
                                            </ol>
                                        <li>Corporate Technology cannot and will not be liable for any loss or damage of account information arising from Customer's failure to comply with this provision.</li>
                                        <li>If a Dedicated server hosting account is registered with details different than those of the person who paid for the account, and in the case of any dispute, Corporate Technology will take the side of the person who paid for the account and shall not provide access to any other person, since - a hosting account owner is and will be considered to be the credit card /PayPal account holder.</li>
                                        <li>The client is obliged not to delete Corporate Technology's SSH key, which provides root access to the server. The root access will be used by Corporate Technology in 2 cases only:</li>
                                            <ol>
                                                <li>When there is a problem with the server and, as a result, certain services do not run properly - Corporate Technology may fix it as he sees fit.</li>
                                                <li>When there is a report from a third party concerning some sort of abuse, for example, spam, Corporate Technology can identify the spammer and suspend him, of which case the client who is in charge of the server will be duly informed. The client, on the other hand, is obliged not to let problems of such kind happen again, whether or not involving that particular abuser. If there are repeated abuse reports - Corporate Technology will charge the client $20 for each report, because the latter is unwilling to assist in terminating the abuse actions on the given server.</li>
                                            </ol>
                                    </ul>
                                <dd>The client is also obliged not to terminate the SNMP service activated by Corporate Technology to keep track of the used IPs on the server. Upon termination of the SNMP service -Corporate Technology will inform the client and the client will have to reactivate the service in 48 hours. If the client does not cooperate - Corporate Technology can suspend the client's access to the server at his discretion.</dd>
                            <dt>VII. NO UNLAWFUL OR PROHIBITED USE</dt>
                                <ol type="1">
                                    <li><b>SERVER USAGE</b></li>
                                        <p>Corporate Technology expressly bans the use of any Dedicated server hardware or software for trying to illegally access another hosted machine or network. Customers may not attempt to gain unauthorized access to any of Corporate Technology's Services, other accounts, computer systems, or networks connected to any of Corporate Technology's Services, through hacking, password mining, or other means of close nature. Customers may not obtain or attempt to obtain any materials or information through any means not intentionally made available by Corporate Technology's Services. For more detailed information regarding all prohibited uses of our server network, please closely review the next chapter of these Terms and our AUP (Acceptable Use Policy). If you still have inquiries over the matter, feel free to contact any of our Customer Care representatives.</p>
                                    <li><b>HOSTED AGREEMENT</b></li>
                                        <p>Server content MUST comply with the parameters stated in this TOS and our Acceptable Use Policy. Web content requirements exclude the publication of illegal content or content considered inappropriate otherwise. If any of Corporate Technology's Services are used for the illegal purpose of SPAM or UCE, they will be instantly terminated. Customers are solely responsible for the content of their messages. Using Corporate Technology's Services in connection with surveys, contests, pyramid schemes, chain letters, junk e-mail, spamming or any duplicative or unsolicited messages (commercial or other) is prohibited. In this light, Corporate Technology expressly forbids any breach of Federal, State or Local regulation, including, but not limited to, the transmission of copyrighted material, material legally judged to be threatening or obscene, and material protected by trade secrets. Corporate Technology's Dedicated servers CANNOT be used for the propagation, distribution, housing, processing, storing, or handling in any way of a lewd, obscene, adult, or pornographic material, or any other material which is considered impermissible by Corporate Technology's Dedicated Terms of Service (this document) and the Acceptable Use Policy (AUP).</p>
                                    <li><b>CUSTOMER AGREEMENT</b></li>
                                        <p>As a condition for Customer use of Corporate Technology's Services, you - the Customer, agree that you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices, and our Acceptable Use Policy (AUP). Corporate Technology's Dedicated servers can be used explicitly and only for legal purposes, and each attempt for taking indecent advantage of the servers, inconsistent with the rules, will be immediately counteracted. Corporate Technology reserves the right at any time to disclose any information, as Corporate Technology deems necessary, to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post, or to remove from the Services any information or materials, in whole or in part, in the sole discretion of Corporate Technology</p>
                                </ol>
                            <dt>IX. E-COMMERCE CUSTOMER WEBSITES</dt>
                                <dd>When buying a Dedicated server to create an e-commerce online store or related e-commerce activities website, the Customer agrees to be fully responsible for the development, operation, and maintenance of his/her web site/ online store and e-commerce activities within that website. The Customer is the only one in charge of approving, processing, and filling client orders and taking care of client requests or complaints. The Customer is also the only one in charge of the payment or satisfaction of all taxes related to his/her web site and online store. The Customer bears the responsibility for ensuring the security and confidentiality of any clients' data (including, without limitation, customer credit card numbers) that he/she may receive in connection with his/her web site or online store.</dd>
                                <dd>Each Customer is in charge of all products and services, as well as all contents and materials appearing online, including:</dd>
                                    <ul>
                                        <li>The accuracy and relevance of Customer Content, and content and material showcased in the Customer store or on its products;</li>
                                        <li>The certainty that the Customer Content and content and materials appearing in the Customer store or on its products do not violate or infringe upon the rights of any person;</li>
                                        <li>The certainty that the Customer Content and the content and materials appearing in the Customer store or on its products are not calumnious or somehow illegal.</li>
                                        <li>Each Customer guarantees to Corporate Technology that he/she owns the right to use the Customer Content and its material, including all text, graphics, sound, music, video, programming, scripts, and applets. Each Customer warrants to Corporate Technology that the use, reproduction, propagation and transmission of the Customer Content and any information and materials contained inside do not, and will not: infringe or abuse any copyright, patent, trademark, trade secret or any other proprietary right of a third party;</li>
                                        <li>Break any criminal law or manifest false advertising, unfair competition, vilification, privacy intrusion; violate a right of publicity or violate any other law or regulation.</li>
                                    </ul>
                                <dd>Each Customer empowers Corporate Technology to reproduce, copy, use and propagate all and any part of the Customer Content for ensuring and operating the Services. Corporate Technology is granted the right to estimate, at its discretion, the extent to which these operations may be carried out.</dd>
                            <dt>X. REFUSAL OF SERVICE</dt>
                                <ol type="1">
                                    <li><b>SERVICE DENIAL</b></li>
                                        <p>Corporate Technology will thoroughly scrutinize all received orders and decide, to its discretion, which of them do not comply with the officially stated requirements for use of Corporate Technology's Dedicated servers. Corporate Technology reserves the right to refuse Service to unlawful parties.</p>
                                    <li><b>SERVICE DISCONTINUANCE</b></li>
                                        <p>Corporate Technology reserves the right to discontinue the Service provision to any subscriber that Corporate Technology deems, at its sole discretion, has violated any condition of the Service explicated in this Terms of Service and/or the Acceptable Use Policy.</p>
                                </ol>
                            <dt>XI. MISCELLANEOUS</dt>
                                <ol type="1">
                                    <li><b>NOTICES</b></li>
                                        <p>Notices, including notices concerning breach of this Agreement, will be sent either to the e-mail address provided by the Customer at sign up OR mailed by first-class mail to the postal address that the Customer has on file with Corporate Technology. In both cases, delivery shall be deemed to have been made five (5) days after the notice has been sent. Notices from the Customer to Corporate Technology should be sent to the address provided on Corporate Technology's website.</p>
                                    <li><b>COMPLAINTS PROCEDURE</b></li>
                                        <p>Corporate Technology guarantees the highest level of Customer Care service round the clock. If you feel dissatisfied with any aspect of the Service, feel free to make use of our complaints procedure detailed below.</p>
                                        <p>To file a complaint, please send us an email to complaints@duoservers.com, specifying the nature of the problem you are experiencing. Please, provide as many details about your particular case as possible. The more we know about the situation, the better, prompt, and adequate our resolution will be.</p>
                                        <p>Here are our steps of addressing every single complaint:</p>
                                            <ul>
                                                <li>Corporate Technology will confirm receipt of your complaint in writing, confirming that the complaint will be addressed without delay. Corporate Technology reserves the right to refuse to investigate a complaint that is frivolous, abusive, or offensive.</li>
                                                <li>A Corporate Technology's Customer Care representative will review your situation, look for an appropriate solution, and inform you of the timeframe within which a response should be expected.</li>
                                                <li>If Corporate Technology's Customer Care representative can't resolve your complaint within the pre-announced period, your complaint will be escalated to a Customer Care manager.</li>
                                                <li>If the Customer Care manager can't resolve your complaint, as well, it will be forwarded to the Company Director, who will investigate the case to ensure that all necessary measures have been taken and will respond in writing within two working days, and reply with a solution within five working days.</li>
                                                <li>An alternative complaint method is submitting a fax to the following number: +1 (714) 441-2900</li>
                                            </ul>
                                    <li><b>TORTUROUS CONDUCT</b></li>
                                        <p>Any way of indecent treatment towards a Corporate Technology's Customer Care employee will be considered as totally inappropriate and unacceptable. Each Customer is expected to communicate in an utterly polite and professional manner with Corporate Technology's employees regarding any request for help or consultation via e-mail, chat, phone, or through our Ticket Desk Support System. Each Customer is expected to avoid any form of emotional outburst or inappropriate language, or any other attempt to be undermined the importance of good ethics in communication and human relations. Any act of close nature, or other types of offensive, abusive, disrespectful, or condescending behavior - oral, written, or delivered by a second party - to members of Corporate Technology's Customer Care stuff will be interpreted as a breach of this TOS. Corporate Technology reserves the right to assess at its sole discretion which behavior is to be considered as a violation of the hereby stated rule and agreement and to undertake according to actions.</p>
                                    <li><b>CUSTOMER CARE SERVICE</b></li>
                                        <p>Technical Support</p>
                                        <p>Due to the specificity of the Service, Customers are fully in charge of the management and maintenance of their Dedicated servers. However, if they need assistance or want to leave this task to our highly-qualified administrator’s department, they can avail of our Fully Managed Support service available at a monthly fee of $30 USD. Additional software installation and troubleshooting, which are not included in the Managed Support Package will cost $60 USD.</p>
                                        <p>Pre-sale and general questions</p>
                                        <p>Customers may use any of the support options listed below:</p>
                                            <ul>
                                                <li>Phone us at: <a href="tel:7144412900"> US Phone +1 (714) 441-2900</a></li>
                                                <li>Use our Live Chat</li>
                                                <li>Send us your details:  <a href="https://www.corporatetech.com/contact-us">Contact Form.</a></li>
                                            </ul>
                                </ol>
                            <dt>XII. GENERAL REGULATIONS</dt>
                                <ol>
                                    <li><b>GOVERNING LAW</b></li>
                                        <p>This Agreement shall be governed by and construed in accordance with the laws of America.</p>
                                    <li><b>ARBITRATION</b></li>
                                        <p>Any controversy or claim arising out of or relating to this Agreement, or any breach of this Agreement, in excess of $250.00 USD will be settled in accordance with the commercial arbitration rules of the American Arbitration Association.</p>
                                    <li><b>ENFORCEMENT</b></li>
                                        <p>Failure to enforce any of the provisions of this Agreement shall in no way be considered to be a waiver of these provisions or to affect in any way the validity of this Agreement. If one or more of the provisions contained in this Agreement are found to be invalid, illegal, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions shall remain in full force and effect.</p>
                                    <li><b>INDEMNIFICATION</b></li>
                                        <p>The Customer agrees to indemnify Corporate Technology, its management body, and employees (each an "Indemnified Party") against any losses, claims, damages, liabilities, penalties, court actions, proceedings, or judgments (collectively, "Losses") to which the Indemnified Party may become subject, and out of which Losses arise or relate to this Agreement or the Customer's use of the Services. The Customer agrees to reimburse the Indemnified Party for all legal and other expenses, including any attorney fees paid by the Indemnified Party in connection with investigating, defending, or settling any Loss whether or not in connection with pending or threatened litigation in which the Indemnified Party is a party.</p>
                                    <li><b>LIMITATION</b></li>
                                        <p>Force Majeure Circumstances - Corporate Technology shall not be liable for any failure in the provision of the Services to the Customer resulting, directly or indirectly, from any (i) weather conditions and natural disasters, (ii) actions of any governmental or military authority, (iii) failure caused by telecommunications or Internet provider, or (iv)other force or occurrence beyond its control.</p>
                                        <p>Damages & Losses - Corporate Technology shall not be liable for (i) any indirect, incidental, special or consequential damages, OR for any loss of profits or loss of revenues resulting from the use of Corporate Technology's Services by the Customer or any third parties, or (ii) any loss of data resulting from delays, non-deliveries or service interruptions.</p>
                                        <p>Service Provision - Corporate Technology provides the Services on a basis, without any warranties, including but not limited to, implied warranties, merchantability, or fitness of the Services for any particular purpose. The Customer shall be solely responsible for the selection, use, and suitability of the Services, and Corporate Technology shall have no liability therefore.</p>
                                    <li><b>ASSIGNMENT</b></li>
                                        <p>Customers do not have the right to assign this Agreement without the explicit permission of Corporate Technology. This Agreement shall be in force and effect to the total benefit of the Customer and Corporate Technology and their successors and permitted assigns.</p>
                                    <li><b>REVISIONS</b></li>
                                        <p>Corporate Technology reserves the right to revise this document at any time, without prior notice. Customers are encouraged therefore to periodically review these Dedicated Terms of Service and the Acceptable Use Policy.</p>
                                </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
