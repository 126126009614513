import React, { Component } from 'react'
import HeroSecurity from '../components/HeroSecurity'
import CustomerCarousel from '../components/CustomerCarousel'
import SecurityServices from '../components/SecurityServices'
import {Link} from 'react-router-dom'
import { pageContact } from '../common/data'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class SecurityCompliance extends Component {
    render() {
        const { darkwebscan, darkweb} = pageContact
        return (
            <div id="security-page" className='services-page'>
                <LazyLoad height={200} once >
                <HeroSecurity 
                    title="SECURITY COMPLIANCE"
                    header='Cyber Security and Compliance by the numbers'
                    data1="95"
                    data2="100"
                    data3="60"
                    p1="of all medical and healthcare institutions have been victims of some form of cyberattack."
                    p2="is the average loss small companies face after a ransomeware attack."
                    p3="of SMB's will go out of business within 6 months of cyber attack."
                />
                </LazyLoad>
                <LazyLoad height={200} once >
                <SecurityServices />
                </LazyLoad>
                <VideoSection
                    title='CorpTech Secures GoRentals Across 18 Locations'
                    poster='/videos/posters/goRentals_poster.png'
                    src='https://acdn.dnamicro.net/corporatetech/Go_Rentals_Testimonial.mp4'
                />
                <div id="security-customercard">
                    <CustomerCarousel />
                </div>
                
                <div className="page-contact">
                    <div className="page-contact--img" data-aos-anchor-placement="center-bottom" data-aos="fade-right" />
                    <div data-aos-anchor-placement="center-bottom" data-aos="fade-left" className="page-contact--actions">
                    <Link to="/contact-us" className={`action--contact ${darkwebscan}`} />
                        <a href={`${darkweb}`} className='action--download' rel="noopener noreferrer" target='_blank' download>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
