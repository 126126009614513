import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import CorporateServices from '../components/CorporateServices'
import CorporateCustomer from '../components/CustomerCarousel/CorporateCustomer'
import HeroCorporate from '../components/HeroCorporate'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Corporate extends Component {
    render() {
        return (
            <div id="corporate-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroCorporate
                    title="CORPORATE SOLUTIONS"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="automate day-to-day operations."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <CorporateServices />
                <VideoSection
                    title='CorpTech Helps Vanguard Electronics Achieve Their Goals Despite COVID-19'
                    poster='/videos/posters/manufacturing_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/manufacturing_industry_vanguard_electronics_case_study.mp4'
                />
                </LazyLoad>
                <div id="corporate-customercard">
                    <CorporateCustomer />
                </div>
                <LazyLoad height={200} offset={100} once >
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
