import React from 'react'
import Particles from 'react-particles-js'
import handleViewport from 'react-in-viewport'
import ReactPlayer from 'react-player'

function VideoSection(props) {
    const Video = (_props) => {
        const { inViewport, forwardedRef } = props
        console.log('@inViewport', inViewport)
        return (
            <div className='viewport-block' ref={forwardedRef}>
                <ReactPlayer
                    loop
                    controls
                    playsinline
                    playing={inViewport}
                    playsinline
                    width="auto"
                    height="auto"
                    url={props.src}
                    config={{
                        file: { 
                          attributes: { 
                            poster: `${props.poster}`
                          } 
                        } 
                      }}
                />
            </div>
        )
    }

    const ViewPortVideo = handleViewport(Video)

    return (
        <div className="section section--withVideo" >
            <Particles
                params={{
                    "particles": {
                        "number": {
                            "value": 100,
                            "density": {
                                "enable": true,
                                "value_area": 650
                            }
                        },
                        "opacity": {
                            "value": 0.5,
                            "random": false,
                            "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                            }
                        },
                        "size": {
                            "value": 3,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#ffffff",
                            "opacity": 0.20517838682439088,
                            "width": 0
                        },
                        "move": {
                            "enable": true,
                            "speed": 6,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "grab"
                            },
                            "onclick": {
                            "enable": true,
                            "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                        "grab": {
                            "distance": 235,
                            "line_linked":{
                                "opacity": 1
                            }
                        }
                        }
                    },
                }}
                className="particles-js"
            />
                <h1 className="carousel__title"><span className="carousel__title--blue">{props.title}</span></h1>
                <div className='video_wrapper'>
                    <ViewPortVideo
                        src={props.src}
                        onEnterViewport={() => console.log('enter')} onLeaveViewport={() => console.log('leave')}
                    />
                </div>
        </div>
    )
}

export default VideoSection