import React, {Component} from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './components/ScrollToTop';
import './common/scripts/burger'
import PageNotFound from './pages/404page';




class App extends Component {

  componentWillMount() {
    AOS.init({
      duration: 500,
    })
  }
 
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop/>
        </BrowserRouter>
      </div>
    );
  }
  
}

export default App;
