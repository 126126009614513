// const navSlide = () => {
//     const burger = document.querySelector('.burger');
//     const nav = document.querySelector('.nav__ul');
//     const navLinks = document.querySelectorAll('.nav__ul li');

//     burger.addEventListener('click', ()=> {
//         nav.classList.toggle('nav-active');
//         navLinks.forEach((links, index)=> {
//             if(links.style.animation) {
//                 links.style.animation = ''
//             } else {
//                 links.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`
//             }
//         });
//         //Burger animation
//         burger.classList.toggle('toggle');


//     });


// }

// navSlide();


