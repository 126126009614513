import React, { Component } from 'react'



export default class GT_CancellationRefund extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Cancellation Refund Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology Cancellation Refund Policy</h1>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>CANCELLATION & REFUNDS</dt>
                                <dd>Corporate Technology accounts are set up on a pre-pay basis. Corporate Technology customers may decide to cancel their accounts at any time, however, they will get a full refund of the service only if the cancellation request is made within 30 days of purchasing of the account, according to our Money Back Guarantee Policy</dd>
                                <dd>To cancel an account, the Customer needs to send an e-mail or support ticket to our Sales Department and provide any authentication credentials requested by Corporate Technology. Other forms of cancellation requests will not be accepted. Any incentives (free bonuses, etc.) offered to the Customer upon sign-up will be also canceled.</dd>
                                <dd><i>Domain name registrations/transfers, service upgrades, </i>and web hosting plan renewals are treated as non-refundable services(see below) and cannot be canceled or refunded after being activated upon customer request. The Customer will have the opportunity to transfer his/her domain away to another host.</dd>
                                <dd>Late cancellations, as well as accounts canceled by Corporate Technology due to TOS violations (where the customer has decided not to fix the violation), do not qualify for the 30-day money-back guarantee.</dd>
                                <dd>All credit requests for VISA, MasterCard, American Express, and Discovery payments are processed within 48 hours of the submission of the refund request (by e-mail or ticket, as mentioned above). The funds will be credited back to the Customer's credit/debit card and affect the Customer's credit/debit card balance in accordance with the credit/debit card issuer's terms of service.</dd>
                                <dd>All credit requests for PayPal payments are processed within 48 hours of the submission of the refund request (by e-mail or ticket, as mentioned above). The funds will be credited back to the Customer's PayPal account and affect the Customer's PayPal balance in accordance with PayPal's terms of service.</dd>
                            <dt>MONEY BACK GUARANTEE POLICY</dt>
                                <dd>Corporate Technology offers a <i>30-day money-back guarantee.</i> If you are not satisfied with our hosting services, you can submit a refund request in a trouble ticket opened from your web hosting control panel, or via email sent to billing@corporatetech.com, and receive a full refund for your hosting plan within 30 days of the purchasing of the account. Please, NOTE that domain name registrations/transfers are non-refundable services</dd>
                                <dd>All recurring payments and all payments made via Western Union or bank wire are non-refundable and are not subject to the 30-day money-back guarantee.</dd>
                            <dt>NON-REFUNDABLE SERVICES</dt>
                                <dd><i>Non-Refundable Services:  all service upgrades, wallet refills and plan renewal services purchased from the Web Hosting Control Panel; all 1-year or multi-year domain registration or transfer services purchased at sign-up or from the Web Hosting Control Panel.</i></dd>
                                <dd>All domain registrations and domain transfers are final, do not include any money-back guarantee, and will be subject to a fee that is calculated based on the actual NON-PROMOTIONAL annual price of the purchased domain's TLD, multiplied by the number of years of registration or transfer. Any refund amount credited back to the Customer in accordance with our 30-day money-back guarantee will not include the cost of the non-refundable services (if any) that have been purchased with the Customer's web hosting plan upon sign-up (i.e. the Customer will be issued a partial refund on the original transaction if he/she has purchased non-refundable services within the web hosting account).</dd>
                            <dt>CHARGEBACKS</dt>
                                <dd>Chargebacks are not considered as a way of refunding. Any Customer who initiates a credit card chargeback in response to a bill or order placed at Corporate Technology will be subject to full investigation.</dd>
                                <dd>When signing up for a web hosting account with Corporate Technology, customers agree to the conditions explicated in our Acceptable Use Policy (AUP) and the Web Hosting Terms of Service. Thus, Corporate Technology, as well as our authorized retailers (PayPal, WorldPay, 2CheckOut), will resort to these documents as an evidence for the Customer's agreement, especially if a Customer sends a chargeback inquiry based on points that he/or she first accepted, but is trying now to refute.</dd>
                                <dd>If/When a chargeback report is received, the first step of our Corporate Technology team will be to try to contact the user. Meanwhile, the user account will be suspended (to protect our system from fraudulent activity and misuse) and will remain suspended until the reasons for the chargeback have been clarified and a decision has been taken for according action.</dd>
                                <dd>If the chargeback has occurred due to some misunderstanding, a reverse payment procedure will be initiated with our authorized retailers and the account will be re-activated.</dd>
                                <dd>If the user has sent a chargeback request based on groundless argumentation (to our judgment and these Web Hosting Terms of Service), then a dispute will be started with our authorized retailers in which the Web Hosting Terms of Service and the Acceptable Use Policy of Corporate Technology will serve as proof of the Customer's agreement. The retailers then, taking into consideration all provisions in these legal documents, will decide whether the Customer has had sufficient grounds for the chargeback or Corporate Technology has the right to a reverse payment.</dd>
                                <dd>In case that the chargeback originates from a fraudulent user and no reverse payment procedure can be initiated, then the respective hosting account will remain suspended.</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
