import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import GovernmentServices from '../components/GovernmentServices'
import GovernmentCustomer from '../components/CustomerCarousel/GovernmentCustomer'
import HeroGovernment from '../components/HeroGovernment'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Government extends Component {
    render() {
        return (
            <div id="government-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroGovernment
                    title="GOVERNMENT AND EDUCATION"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="increase uptime and employee productivity."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <GovernmentServices />
                <VideoSection
                    title='CorpTech Helps Vanguard Electronics Achieve Their Goals Despite COVID-19'
                    poster='/videos/posters/manufacturing_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/manufacturing_industry_vanguard_electronics_case_study.mp4'
                />
                </LazyLoad>
                <div id="gov-customercard">
                    <GovernmentCustomer />
                </div>
                <LazyLoad height={200} offset={100} once >
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
