import React, { Component } from 'react'



export default class WH_AcceptablePolicy extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Acceptable Use Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Acceptable Use Policy</h1>
                    <p className="terms__description--padding-small">An Acceptable Use Policy (AUP) is a set of rules and conditions compiled by a network, system or a website owner. These rules explicitly define and restrict the ways in which the respective network, system or website may be used.
                    </p>
                    <p className="terms__description--padding-small">The AUP of Corporate Technology aims to clearly outline the purposes for which our customers CANNOT utilize our hosting services and position data on our network servers.
                    </p>
                    <p className="terms__description--padding-small">This Acceptable Use Policy (AUP) is an integral part of theWeb Hosting Terms of Service which each customer agrees to abide by when signing up for an account with our web hosting company. Any violation of this AUP will be deemed a violation of our Web Hosting Terms of Service.
                    </p>
                    <p className="terms__description--padding-small">We, at our own discretion, shall determine whether an act constitutes a breach of this AUP terms and misuse of our services. We reserve our right to be the sole arbiter in determining the sufficiency of the presented evidences. We will respond accordingly, and/or immediately terminate the provision of the services, shall we receive a report, and/or have sufficient proof, of any prohibited conduct outlined in this AUP, or any other activity threatening the security of our hosting environment and/or our network of hosting servers.
                    </p>
                    <p className="terms__description--padding-small">In order to protect our company’s reputation and responsibilities, and guarantee state-of-the-art and reliable hosting services to all our customers, as well as privacy and security for all users, we – Corporate Technology - hereby outline the following AUP rules and conditions to come into force for each customer signing up for a web hosting account with our company:
                    </p>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <ol className='colored_ordered_list less_than_Ten'>
                            <li><a href="#WH_system_abuse">System Abuse</a></li>
                            <li><a href="#WH_spam">Spam and Spamvertizing</a></li>
                            <li><a href="#WH_unlawful">Inappropriate or Unlawful Content</a></li>
                            <li><a href="#WH_copyrights">Copyrights</a></li>
                            <li><a href="#WH_corrective_actions">Corrective Actions</a></li>
                            <li><a href="#WH_reporting_violation">Reporting Violations</a></li>
                            <li><a href="#WH_revisions">Revisions</a></li>
                        </ol>
                        <dl className='terms_individual_details'>
                            <dt id="WH_system_abuse">1. SYSTEM ABUSE</dt>
                                <dd>Any customer in violation of our system or network security is subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:</dd>
                                    <ul>
                                        <li>Unauthorized access, use, probe, or scan of our system’s security, authentication measures, data or traffic. Interference with the service to any user, host or network including, but not limited to: e-mail bombing, flooding, deliberate attempts to overload the system and broadcast attacks, forging of any TCP/IP packet header or any part of the header information in an e-mail or newsgroup posting.</li>
                                        <li>Any attempt at doing harm to a server or a customer of our web hosting services:</li>
                                    </ul>
                                <dd>Corporate Technology will immediately and resolutely react to any attempt at misuse of an Internet account or computer, or any attempt to gain access to an internet account or computer by a Corporate Technology customer unauthorized by the account/computer owner. Such attempts include, but are not limited to the following: 'Internet scamming’ (tricking other people into releasing their passwords, etc.), password robbery, security hole scanning, running proxy - proxy lists and any kind of proxy scripts (including, but not limited to php proxy and nph proxy), introducing of any computer programs (viruses, Trojan horses, trap doors, back doors, worms, time bombs, packet bombs, cancel bots, etc.) that are intended to negatively interfere with, intercept, irreversibly damage or take hold of any system, data or personal information. It WILL NOT matter whether the attacked account or computer belongs or not to Corporate Technology services. The fraud will be subject to actions based on the very nature of the attack and those may represent a warning, an account suspension or cancellation, and/or civil or criminal legal actions.</dd>
                                    <ul>
                                        <li>Excessive use of system resources:</li>
                                    </ul>
                                <dd>‘Background process’ is a program that runs in the background of the operating system. What is specific for a background process is that, once started, it runs simultaneously with other foreground processes. This means that the shell executing the background operation does not wait for it to finish or terminate, as it does with foreground processes, but is free to start executing more operations along with the background one. Typical background processes are ircd, irc bot, bnc, and many others.</dd>
                                <dd>Background processes are not permitted on any of our shared hosting servers, as they usually lead to CPU overload and can be used for malicious and/or illegal actions. As our primary task is to provide the highest quality web hosting service to all our customers, we cannot permit any background processes to be executed and threaten the performance of our server network. Our shared hosting system does not allow a big multitude of users to simultaneously use the system memory and processing time of our servers.</dd>
                                    <ul>
                                        <li>Excessive use of system resources:</li>
                                    </ul>
                                <dd>In order to ensure higher server performance and quality of hosting service all shared hosting plans are subject to certain resource limitations concerning, but not limited to, server CPU usage, Memory usage, disk-space and traffic. If a hosting account exceeds the allowed amount of system resources the account owner will be immediately notified to take actions in order to reduce the usage. If such action is not instantly taken by the owner the account will be suspended. If an excessive CPU usage is detected the account owner will be suggested to place an order for a CPU usage upgrade or upgrade to a plan which allows higher CPU usage.</dd>
                                <dd>If any hosting account is found to be causing degradation of the server and/or our hosting network’s performance, regardless of the reason, the account will be subject to suspension or immediate removal, depending on the situation. Corporate Technology will be the sole arbiter in determining whether a given account is excessively using the server resources and/or causing a server degradation.</dd>

                            <dt id="WH_spam">2. SPAM AND SPAMVERTIZING</dt>
                                <dd>SPAMMING IS PROHIBITED. Users MAY NOT utilize our web hosting services to transmit, distribute or deliver any unsolicited bulk or commercial messages over the Internet (an action known as "spam" or "spamming").</dd>
                                <dd>Spamming is a violation of ethical norms and will be interpreted as an infringement. Corporate Technology will not tolerate the sending of unsolicited bulk or commercial messages through our services, as well as through another service that is mentioning, in some way, our customer’s domain name or implies the utilization of our network or our email services for sending the unsolicited bulk or messages.</dd>
                                <dd>SPAMVERTISING IS PROHIBITED. 'Spamvertizing’ refers to the practice of sending unsolicited spam messages for the purpose of advertising a particular website.</dd>
                                <dd>If such emails are relayed through 3rd party (e-mail spoofing) and/or are promoting a domain hosted on our servers, this will lead to immediate account suspension unless an evidence of legitimacy for non-involvement of the domain owner in the particular illegal action is provided.</dd>
                                <dd>’Email spoofing’ is a fraudulent email activity in which the sender’s (3rd party) email address and other parts of the email header are changed so as to appear that the email has been sent from a different source (email address owner/server).</dd>
                                <dd>'Unsolicited message’ is a message that is sent against the privacy policy of a newsgroup, or is sent to a recipient without their explicit permission. We, at our sole discretion, shall determine whether any of the messages you are sending is spam or spamvertizing. For your information, spamming generally includes, but is not limited to the following</dd>
                                    <ul>
                                        <li>Sending of unsolicited messages in bulk, or sending of unsolicited emails, which provoke complaints from recipients;</li>
                                        <li>Sending of junk mail;</li>
                                        <li>Use of distribution lists that include people who have not given prior explicit permission to be involved in such a distribution process;</li>
                                        <li>Posting commercial ads to USENET newsgroups that do not permit it</li>
                                        <li>Posting articles containing binary encoded data to non-binary newsgroups;</li>
                                        <li>Excessive and repeated posting of off-topic messages to newsgroups;</li>
                                        <li>Excessive and repeated cross-posting;</li>
                                        <li>E-mail harassment of another Internet Customer or Customers, including but not limited to, transmitting of any threatening, libellous or obscene material, or material of any nature that may be deemed offensive;</li>
                                        <li>E-mailing of age-inappropriate communications or content to anyone under the age of 18.</li>
                                    </ul>
                            <dt id="WH_unlawful">3. INAPPROPRIATE OR UNLAWFUL CONTENT</dt>
                                <dd>CONTENT published or transmitted via Corporate Technology’s hosting services includes website content, email content, forum/blog postings, chat content, and/or any other type of content hosted on our servers, published online or transmitted through our network’s services.</dd>
                                <dd>The user of our services is NOT permitted to post online content or have links to content that:</dd>
                                    <ul>
                                        <li>is defamatory, abusive or obscene, violates a person’s reputation/privacy, is reasonably deemed offensive by the web community, is anti-religious or anti-human rights, or contains any type of threatening or hate speech, encourages harassment or physical harm to any group or individual, or is otherwise malicious/fraudulent and may result in complaints/liabilities/or retaliation against Corporate Technology by offended viewers;</li>
                                        <li>promotes or encourages illegal activities (e.g. illegal drugs, illegal gambling, arms trafficking, etc.) or violates any applicable law, statute, ordinance or regulation, including but not limited to the laws and regulations governing export control, unfair competition, false advertising, consumer protection, etc.</li>
                                        <li>represents pornography – any type of pornographic/adult material, including child pornography, or advertisements of pornographic websites;</li>
                                        <li>represents infringement on copyright, patents, trademarks, trade secrets, or third party's intellectual property or proprietary rights, including pirated computer programs, hacking/phreaking software, warez or any type of software/content (including also ROMs, ROM Emulators, media files – MP3, AVI, .RM, etc., torrent files, and others) that is copyrighted, is NOT uploaded in compliance with its license agreement or is NOT freely available for distribution.</li>
                                    </ul>
                            <dt id="WH_copyrights">4. COPYRIGHTS</dt>
                                <dd>Copyrighted material must not be placed on customers' accounts without the explicit permission of the copyright owner or a person explicitly authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation and, upon confirmation, will promptly remove the infringing material from the Services. Further procedures (in accordance with our stated below corrective measures) will be carried out if necessary. We will assume no liability to any Customer of the Services for the removal of any such material.</dd>
                            <dt id="WH_corrective_actions">5. CORRECTIVE ACTIONS</dt>
                                <dd>We reserve our right to be the sole arbiter in determining the seriousness of each infringement and to immediately take corrective actions.</dd>
                                <dd>We will disable the customer's account if we deem necessary, at our sole discretion. Upon closing of the customer’s account we will send an email notification to the customer, stating the reason for the termination and quoting our terms’ chapter that has been violated. The customer will be given an opportunity to respond and take measures to remedy the situation should he/she consider him/herself abused by a third party. Upon fulfilling this obligation, we may reactivate the customer’s account, if we find, at our own discretion, sufficient evidence pro-customer's side.</dd>
                                <dd>Closed accounts due to repeated violations will not be reactivated. A backup may be requested, however it will be subject to certain penalty fees imposed according to the breach of this AUP terms. The final penalty fee will be determined by the type and frequency of the violations.</dd>
                            <dt id="WH_reporting_violation">6. REPORTING VIOLATIONS</dt>
                                <dd>If you need to report a violation of this AUP agreement or believe that you or your system has been subject to attack originating from our web hosting system, please contact us immediately. Our professional team will investigate the situation and provide you with full assistance.</dd>
                            <dt id="WH_revisions">7. REVISIONS</dt>
                                <dd>We reserve our right to change this AUP at any time, without prior notice. We encourage our users to periodically review this Acceptable Use Policy and our Web Hosting Terms of Service.</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
