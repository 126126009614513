import React from 'react'
import ReviewsCarousel from '../components/ReviewsCarousel'
import CustomerCarousel from '../components/CustomerCarousel'
import {NavLink} from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import { industries } from '../common/data.js'

const scrollFunctionLogo = () => {
  if (document.body.scrollTop > 8 || document.documentElement.scrollTop > 8) {
  document.getElementById('logo').style.display = 'none';
  } else {
    document.getElementById('logo').style.display = 'block';
  }
}


window.onscroll =  function() {scrollFunctionLogo()}


function HomePage(props) {
  localStorage.setItem('title', 'Other')
  
  return (
    <main className='homepage'>
      <LazyLoad height={200} offset={100} once >
        <div className='hero'>
          <a href="/"><img className='hero_logo' id="img-logo" src="/imgs/btmp-logo.png" alt="logo"/></a>
          <div data-aos="fade-down" data-aos-anchor-placement="top-top" className="hero_title">
            Relax, we'll take IT from here.</div>
          <div className='hero_buildings'>
            <img className='building' src="/imgs/hero/buildings.png" alt='building'/>
            {
              industries.map(industry => (
                <a href={industry.link} target='_blank'>
                  <img className={`industry ${industry.link}`} src={`/imgs/hero/buildings/${industry.link}.png`} alt='' />
                </a>
              ))
            }
          </div>
        </div>
      </LazyLoad>
      <div>
        <ReviewsCarousel props={props}/>
      </div>
        
      <div className="customers">
        <CustomerCarousel />
      </div>
    </main>
    
  )
}

export default HomePage
