import React, { Component } from 'react'
import HeroRetail from '../components/HeroRetail'
import RetailServices from '../components/RetailServices'
import PageContact from '../components/PageContact'
import RetailCustomer from '../components/CustomerCarousel/RetailCustomer'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Retail extends Component {

    render() {
        return (
            <div id="retail-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                    <HeroRetail
                    title="RETAIL SOLUTIONS" 
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="streamline sales and customer service."
                    />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <RetailServices />
                <VideoSection
                    title='California Closets: CorpTech Proactively Solves Our Technology Problems'
                    poster='/videos/posters/retail_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/retail_industry_california_closets_case_study.mp4'
                />
                </LazyLoad>
                <div id="retail-customercard">
                    <RetailCustomer />
                </div>
               <LazyLoad>
                <PageContact />
               </LazyLoad>
            </div>
        )
    }
}
