import React, { Component } from 'react'
import TermsTablePrice from '../../TermsTablePrice'



export default class SD_ServerTerms extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)


        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Semi-Dedicated Servers Terms</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Semi-Dedicated Servers Terms of Service</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our Semi-Dedicated Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred to as 'Corporate Technology' in this document.</p>
                    <p className="terms__description--padding-small">When signing up for a Semi-Dedicated hosting account with Corporate Technology, the Customer agrees to abide by the rules and provisions explicated in these Semi-Dedicated Terms of Service, as well as all the coherent policies (Order Verification Policy, Acceptable Use Policy, etc.) mentioned herein.</p>
                    <p className="terms__description--padding-small">The Customer of our Services should also be aware that when signing up for a Semi-Dedicated hosting account with Corporate Technology, he/or she agrees to position data on our servers and make it accessible to the wide internet public. In this respect, the Customer understands and agrees that it is beyond the power of Corporate Technology to restrict any public access to this data and guarantee the security of the Customer's files. The Customer, therefore, is completely in charge of protecting his/her web content on our servers and should consider carefully each piece of information before uploading it to his/her newly allocated Semi-Dedicated server space. We highly recommend that Customers avoid publishing online personal data that could be abused.</p>

                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>I. SERVICE AGREEMENT; ENTIRE AGREEMENT</dt>
                                <dd>Corporate Technology shall provide its Services to You - the Customer - under the Terms of Services explicated in this Service Agreement. The Agreement is intended to keep the integrity of both Corporate Technology and the Customer and to protect both parties from liability. This Service Agreement will be enforced to ensure the speed, power, performance, and reliability of our Services.</dd>
                                <dd>The Service Agreement, together with the Order Form, the Acceptable Use Policy (AUP), and any other policies or agreements mentioned in this document, constitute the whole act of convention between Corporate Technology and the Customer.</dd>
                            <dt>II. SEMI-DEDICATED SERVER HOSTING SERVICES - FEATURES, RULES FOR ACTIVATION AND PAYMENT BASIS</dt>
                                <ol>
                                    <li>SERVICE FEATURES</li>
                                        <p>Basic Features:</p>
                                        <ol>
                                            <li>Disk Space - UNLIMITED</li>
                                            <li>Traffic - UNLIMITED</li>
                                            <li>Hosted Domains - UNLIMITED</li>
                                            <li>Domain Registration/Transfer - available at a regular or discounted price (depending on the chosen TLD) at Sign Up, and also (later on) as an upgrade from the Customer's Web Hosting Control Panel (at a regular price). Each account can host a maximum of one discounted domain name. Domain registrations are final and are not subject to refund. More information regarding domain names can be found further in this TOS (Terms of Service), in our Domain Name Registration Agreement, and the Domain Name Dispute Resolution Policy.</li>
                                            <li>E-mail accounts - UNLIMITED</li>
                                            <li>MySQL Databases & Database Storage - UNLIMITED</li>
                                            <li>PgSQL Databases & Database Storage - The Semi-Dedicated 1 package has a fixed PgSQL database & database storage quotas – 5 databases and 160 MB database storage. Additional database storage is available as an upgrade. Contact sales for pricing.</li>
                                            <li>The Semi-Dedicated 2 package comes with UNLIMITED PgSQL database & database storage quotas.</li>
                                                <p>Optional Features:</p>
                                                <ul>
                                                    <li>IP Address - For many customers, IP address is important for starting SSL hosts, that's why we have dedicated IP addresses featured in both our Semi-Dedicated packages.</li>
                                                    <li>SSH - Optional for the Semi-Dedicated 1 package; included in the Semi-Dedicated 2 package.</li>
                                                    <li>ID Protection - This service ensures that your private contact information will not be visible in the domain's WHOIS details. Optional with the two Semi-Dedicated packages, but available only with certain TLDs (not all TLD registries support the IDP service).</li>
                                                </ul>
                                        </ol>
                                        <p>More comprehensive information about all features included in our Semi-Dedicated hosting services is available on our semi-dedicated hosting pages.</p>
                                    <li>INSTALLATION AND TROUBLESHOOTING</li>
                                        <p>Each customer will be able to take advantage of our Installation & Troubleshooting service upgrade. It allows users to get one-time expert assistance from our technical support team or system administrators for 1 hour. The Installation & Troubleshooting is intended for:</p>
                                        <ul>
                                            <li>Hacked sites cleaning and restoration service If you find your website hacked or reported to be infected you can rely on our help getting this fixed for you. This service included a backup of the current content, cleaning and/or restoring broken files from original script installation or restore from a previous copy.</li>
                                            <li>Script, theme, plugin installation service If you need any software installation, theme/template, or even plugin installation that you do not wish to deal with, we can do it for you. We will backup the current site (if any) and complete the desired installation for you.</li>
                                            <li>Scripts troubleshooting and debugging services In case you have an issue with a software that you are unable to handle, we can do the troubleshooting and fix it for you.</li>
                                            <li>Manual files, database, or email transfers between hosting accounts using our services. If you want to move a site or a few over to another account we can handle the migration for you hassle-free.</li>
                                            <li>The Installation & Troubleshooting will not apply in the following cases: Our installation service covers only fresh installations (no upgrades). We will not perform custom work or upgrade to a recent version of already existing scripts.</li>
                                            <li>The service will not include any configuration of the scripts beyond their installation. After the scripts are being activated and operational any further tweaking and setup are done by the client.</li>
                                            <li>We will only provide installation and troubleshooting if the hosting is provided by us on our Linux Based Servers. We do not provide the service for any Windows, Linux, and MAC servers which are not powered and managed by us.</li>
                                            <li>We don-t provide any warranties and will not be held responsible for any data loss or any script, theme, plugin not operating as intended.</li>
                                        </ul>
                                    <li>SERVICE GUARANTEES</li>
                                    <p>Corporate Technology guarantees 99.9% network uptime and server stability. This, however, does not refer to problems stemming from:</p>
                                    <ol>
                                        <li>Server Hardware Breakdown;</li>
                                        <li>User error(s) or purposeful interruption(s) of the user service (e.g. if the client shuts his/her server down, Corporate Technology is not responsible for the downtime);</li>
                                        <li>Failure due to software that is not explicitly supported by Corporate Technology. If a hardware crash provoked by the Customer consequently happens, Corporate Technology is beyond the control and responsibility for the resulting downtime.</li>
                                        <p>For more information, please refer to our <a href="https://www.corporatetech.com/semi-dedicated-service-guarantee">Service Level Guarantees.</a></p>
                                    </ol>
                                    <li>DATA BACKUP</li>
                                    <p>Data backup is performed daily. The backup includes all the contents of a client's /www/ folder, as well as all databases. Important: The available space for a Customer's backup is 5 GB of disk storage. User accounts with content over 5 GB cannot be (partially or entirely) backed up.</p>
                                </ol>
                            <dt>III. SERVICE ACTIVATION AND CONTINUANCE TIMESCALES</dt>
                                <ol>
                                    <li>30-DAY FREE TRIAL</li>
                                        <p>All our semi-dedicated servers include a 30-day free trial option, which can be enabled as soon as a given Customer has created an account. Only new Customers can take advantage of this possibility. The free trial period itself will expire 30 days after activation.</p>
                                        <p>During the free trial period, the account will operate with several limitations, which may differ based on the chosen package. More information on the subject will be sent to the Customer on the free trial period's start date. After the trial period ends, the Customer will no longer have access to the Service unless they complete their purchase.</p>
                                        <p>All customer's data will be kept for up to 30 days after the end of the free trial period. In case the customer does not complete the order procedure within that period, the data will be deleted from our servers.</p>
                                        <p>At any given time during the free trial period, the Customer may convert their free account into a standard paid account by clicking the "Complete Order" button in the Web Hosting Control Panel.</p>
                                    <li>SERVICE ACTIVATION</li>
                                        <p>For newly signed up clients - The Services are activated immediately after our Sales Department gets in touch with the Customer over the phone to verify the payment details (this is required due to the high fraud activity spreading online, and to be prevented fraudulent orders). The payment verification procedure is obligatory and if we fail to approve a transaction within 48 hours of the payment submission, the funds will be credited back to the payer and the order will be canceled.</p>
                                        <p>For existing customers - The Services for existing Customers, including upgrade services and renewals, are activated immediately after our Sales Department receives the according service payment.</p>
                                    <li>CONTINUANCE TIMESCALES</li>
                                        <p>Semi-Dedicated Accounts - The newly-created Semi-Dedicated hosting accounts with Corporate Technology are valid for 1 month. They can be renewed monthly, i.e. for another month that will be added to the official expiration date of the Semi-Dedicated server account.</p>
                                        <p>Domain Name Registrations/Transfers - Corporate Technology offers for registration/transfer all TLDs (Top-Level Domains) displayed in the table below. A domain name can be purchased either at sign up together with the Semi-Dedicated account (at a regular or PROMOTIONAL price) or separately (later on) as an upgrade from the Customer's Web Hosting Control Panel. The table below displays the minimum and maximum registration periods, as well as the annual NON-PROMOTIONAL prices of all TLDs:</p>
                                        <TermsTablePrice/>
                                        <p>Domain Renewals - Domain names are valid for the period of their registration (domain names can be registered for up to 10 years, depending on the domain name's TLD extension). After the expiration of a domain name, several stages follow regarding its renewal.</p>
                                            <ul>
                                                <li>The domain name Redemption Grace Period (RGP) is a registry level period of up to 30 days after a domain name's expiration date. During this period the domain name is kept on hold by the Registrar before it gets deleted. The domain registrant is allowed to retrieve the domain name from deletion, but this process costs an additional redemption fee. This extra 30-day period extends the time available for renewing domain names. However, all domains that enter a redemption period are removed from the zone files (the list of domain names currently in the global DNS), therefore any web site or e-mail services associated with the domain name will stop working. Extended Redemption Grace Period - the extended redemption period adds another 7 - 30 days to the domain name's redemption period. During this period the domain name owner is allowed to retrieve the domain name from deletion by contacting the domain's registrar. After the redemption period of an expired domain name is over, follows the Pending Delete Period - a registry level period of up to 5 days during which no one is allowed to renew the domain name and it cannot be newly registered because it's still not returned to the public pool of domain names. Released by Registry Period - after the domain name's 'Pending Delete Period' is over, the domain name is released back into the public pool of domain names and is newly available for registration.</li>
                                            </ul>
                                        <p>We strongly recommend that Customers renew their domain registrations before their domain names are placed into the redemption period. After a domain name transits in the redemption period, we are not able to prevent its zone files from being removed from the DNS system, and any web site and associated services will cease working.</p>
                                        <p>We can assist Customers in retrieving their domain names only if the domain names were registered with us. Customers will be charged a REDEMPTION FEE of $270.00 for each domain registration renewal. If the domain name was registered with another registrar, the Customer needs to contact that registrar for more information regarding its retrieving.</p>
                                </ol>
                            <dt>IV. CANCELLATION, REFUND & MONEY-BACK; CHARGEBACKS</dt>
                                <ol>
                                    <li>CANCELLATIONS</li>
                                        <p>To cancel a Semi-Dedicated account, the Customer must send a support ticket to Corporate Technology's Sales Department via his/her Web Hosting Control Panel and provide any authentication credentials requested by Corporate Technology. Other forms of cancellation requests will not be accepted.</p>
                                    <li>REFUNDS</li>
                                        <p>Semi-Dedicated server accounts - The Semi-Dedicated accounts are set up on a monthly prepay basis. Customers may cancel their accounts at any time, however, they will not be entitled to a refund for the unused period of the prepaid month, since these services do NOT include Money Back Guarantee. Any incentives offered to the Customers upon sign-up will be also canceled.</p>
                                        <p>Domain Name Registrations/Transfers - Domain name registrations/transfers are also treated as non-refundable services and cannot be canceled or refunded after activated upon Customer request. The domain name owner will have access to a domain management account and will be able to transfer the domain name to another registrar.</p>
                                    <li>CHARGEBACKS</li>
                                        <p>Chargebacks are not considered as a way of refunding. Any Customer who initiates a credit card chargeback in response to a bill or order placed with Corporate Technology will be subject to full investigation.</p>
                                        <p>When signing up for a Semi-Dedicated server account with Corporate Technology the Customer agrees to the Terms of Services explicated in this document (and its coherent policies). Thus, Corporate Technology and its authorized retailers (PayPal, WorldPay, 2CheckOut), will resort to these documents as an evidence for the Customer's agreement, especially if a Customer sends a chargeback inquiry based on points that he/or she first accepted, but is trying now to refute.</p>
                                        <p>If/When a chargeback report is received, the first step of Corporate Technology's Customer Care team will be to try to contact the user. Meanwhile, the Customer's Semi-Dedicated account will be suspended (to protect our system from fraudulent activity and misuse) and will remain suspended until the reasons for the chargeback have been clarified and a decision has been taken for according action.</p>
                                        <p>If the chargeback has occurred due to some misunderstanding, a reverse payment procedure will be initiated with the authorized retailers, and eventually, the account will be re-activated.</p>
                                        <p>If the Customer has sent a chargeback request based on groundless argumentations (according to Corporate Technology's judgment and these Semi-Dedicated Terms of Service), then a dispute will be started with the authorized retailers in which these Terms of Service and the AUP of Corporate Technology will serve as evidence of the Customer's agreement. The retailers then, taking into consideration all provisions in these legal documents, will decide whether the Customer has had sufficient grounds for the chargeback or Corporate Technology has the right to a reverse payment.</p>
                                </ol>
                            <dt>V. CUSTOMER RESPONSIBILITIES</dt>
                                <ol>
                                    <li>ACCOUNT REGISTRATION AND MANAGEMENT</li>
                                        <p>In consideration of their use of the Service, Customers agree to:</p>
                                        <ul>
                                            <li>Provide true, accurate, current, and complete information about themselves as prompted by the registration form of the Service. Maintain and promptly update their registration data to keep it true, accurate, current, and complete. If the Customer provides any information that is untrue, inaccurate, not current or incomplete, or Corporate Technology has reasonable grounds (at its sole discretion) to suspect that such information is untrue, inaccurate, not current or incomplete, Corporate Technology has the right to suspend or terminate the Customer's account and refuse all current or future use of the Service (or any portion thereof). If untrue details are used to avoid VAT the customer can be held responsible by the respective national tax collecting agency.</li>
                                            <li>When introducing any changes to their contact details, Customers are obliged to notify Corporate Technology in 10 business days after the changes become valid. The introduction of changes should be done online through the customers' Web Hosting Control Panel, not via e-mail.</li>
                                            <li>Customers will receive a password and account designation upon completing the registration process of the Service. Customers are responsible for keeping the confidentiality of their passwords and accounts and are fully responsible for all activities that occur under that password or account. Customers agree to: a) immediately notify Corporate Technology about any unauthorized use of their password or account or any other breach of security; b) ensure that they safely exit (log out from) their account at the end of each session. Corporate Technology cannot and will not be liable for any loss or damage of account information arising from Customer's failure to comply with this provision.</li>
                                            <li>If a Semi-Dedicated hosting account is registered with details different than those of the person who paid for the account, and in the case of any dispute, Corporate Technology will take the side of the person who paid for the account and shall not provide access to any other person, since - a hosting account owner is and will be considered to be the credit card /PayPal account holder.</li>
                                            <li>CPU Cores (or usage of the Central Processing Unit(s) of the web server the Customer's Semi-Dedicated account is hosted on) - The CPU Cores value represents the ability of the semi-dedicated server to use up to 100% of the CPU time of one (or more) server core(s) for no longer than 24 hours.
                                                <br></br><br></br>If we do register a Customer over-usage of CPU, we will immediately notify the Customer and offer him/her to place an order for a CPU upgrade.
                                                <br></br><br></br>We reserve the right to block files or scripts that excessively use CPU. We may also suspend the Customer account in the case of a registered impact on other accounts on the server or disturbance caused to its functioning.
                                            </li>
                                            <li>E-mails - Customers are NOT allowed to send more than 10,000 emails per day per Semi-Dedicated server hosting account. Each email message can have up to 300 recipients. Each email account is entitled to 10 GB of storage space and up to 200,000 stored messages. All active email accounts under a semi-dedicated server must not exceed 100 GB in size.
                                                <br></br><br></br>You can create as many mailboxes as you need under one hosting account as long as you do not interfere with the other users on the server.
                                                <br></br><br></br>If a given user creates more than 3000 mailboxes under one hosting account, they could cause a high server load by just modifying a password or changing an alias.
                                                <br></br><br></br>This is why, if a particular hosting account simultaneously accommodates multiple mailboxes and its owner often executes changes like the ones mentioned above, then we'll be forced to disable the respective account and ask the client in question to migrate to a more powerful hosting environment where mailbox modification operations cannot lead to server performance issues.
                                            </li>
                                            <li>File Quota - Each Semi-Dedicated hosting account is entitled to host up to 300,000 files.</li>
                                            <li>Disk Space & Traffic - Corporate Technology offers generous amounts of disk space and network bandwidth. Customers must not allow other websites or hosting servers to link to files stored in their hosting accounts and thus steal network traffic. To escape unjust distribution of the resources, as well as possible sharing of illegal content, Customers are not allowed to use their hosting accounts as a remote storage server only or solely to share (un)archived images, programs, or sound files. At least 80% of all files and/or total megabytes used (including MP3, WAV, RA, GIF, JPG, ZIP, RAR, but not limited to these file types) in each hosting account must be a part of actual, active website(s) content, i.e. all content files must have corresponding HTML or PHP files that link to them. Audio, graphics, and/or video files must be under 25% of the current web hosting disk usage and must never exceed 50 GB in size. Furthermore, such media files may amount to no more than 50% of the customer's total monthly traffic usage. Upon a support ticket request by our customer care team, each Customer must be able to prove that a given file is a constituent part of an actual page and not only stored on the server within 24 hours of the request. If the client fails to react within 24 hours after the request and does not remove the file/files in question, or if the apportioned traffic quota continues to be used for such purposes, Corporate Technology reserves the right to suspend the account and/or to remove the aforesaid content from the server. In such cases, refunds are not applicable and the money-back guarantee is no longer valid.</li>
                                            <li>MySQL Usage - Each database hosted in our system can be used only by the user who created it. Another user, whose account is hosted on the same or another server, cannot use the database created in the first-mentioned Customer account. The MySQL database has a limit of 90,000 queries per hour for Semi-Dedicated package 1 and 130,000 queries for Semi-Dedicated package 2. If the Customer has reached the limit of allowed queries per a given hour, he/she will not be able to make any more queries. In the next hour, the Customer will have access to the database again and the counter will be reset.</li>
                                        </ul>
                                    <li>ACCOUNT USAGE</li>
                                        <ul>
                                            <li>CPU Cores (or usage of the Central Processing Unit(s) of the web server the Customer's Semi-Dedicated account is hosted on) - The CPU Cores value represents the ability of the semi-dedicated server to use up to 100% of the CPU time of one (or more) server core(s) for no longer than 24 hours.
                                                <br></br><br></br>If we do register a Customer over-usage of CPU, we will immediately notify the Customer and offer him/her to place an order for a CPU upgrade.
                                                <br></br><br></br>We reserve the right to block files or scripts that excessively use CPU. We may also suspend the Customer account in the case of a registered impact on other accounts on the server or disturbance caused to its functioning.
                                            </li>
                                            <li>E-mails - Customers are NOT allowed to send more than 10,000 emails per day per Semi-Dedicated server hosting account. Each email message can have up to 300 recipients. Each email account is entitled to 10 GB of storage space and up to 200,000 stored messages. All active email accounts under a semi-dedicated server must not exceed 100 GB in size.
                                                <br></br><br></br>You can create as many mailboxes as you need under one hosting account as long as you do not interfere with the other users on the server. If a given user creates more than 3000 mailboxes under one hosting account, they could cause a high server load by just modifying a password or change an alias.
                                                <br></br><br></br>This is why, if a particular hosting account simultaneously accommodates multiple mailboxes and its owner often executes changes like the ones mentioned above, then we'll be forced to disable the respective account and ask the client in question to migrate to a more powerful hosting environment where mailbox modification operations cannot lead to server performance issues.
                                            </li>
                                            <li>File Quota - Each Semi-Dedicated hosting account is entitled to host up to 300,000 files.</li>
                                            <li>Disk Space & Traffic - Corporate Technology offers generous amounts of disk space and network bandwidth. Customers must not allow other websites or hosting servers to link to files stored in their hosting accounts and thus steal network traffic. To escape unjust distribution of the resources, as well as possible sharing of illegal content, Customers are not allowed to use their hosting accounts as a remote storage server only or solely to share (un)archived images, programs, or sound files. At least 80% of all files and/or total megabytes used (including MP3, WAV, RA, GIF, JPG, ZIP, RAR, but not limited to these file types) in each hosting account must be a part of actual, active website(s) content, i.e. all content files must have corresponding HTML or PHP files that link to them. Audio, graphics, and/or video files must be under 25% of the current web hosting disk usage and must never exceed 50 GB in size. Furthermore, such media files may amount to no more than 50% of the customer's total monthly traffic usage. Upon a support ticket request by our customer care team, each Customer must be able to prove that a given file is a constituent part of an actual page and not only stored on the server within 24 hours of the request. If the client fails to react within 24 hours after the request and does not remove the file/files in question, or if the apportioned traffic quota continues to be used for such purposes, Corporate Technology reserves the right to suspend the account and/or to remove the aforesaid content from the server. In such cases, refunds are not applicable and the money-back guarantee is no longer valid.</li>
                                            <li>MySQL Usage - Each database hosted in our system can be used only by the user who created it. Another user, whose account is hosted on the same or another server, cannot use the database created in the first-mentioned Customer account. The MySQL database has a limit of 90,000 queries per hour for Semi-Dedicated package 1 and 130,000 queries for Semi-Dedicated package 2. If the Customer has reached the limit of allowed queries per a given hour, he/she will not be able to make any more queries. In the next hour, the Customer will have access to the database again and the counter will be reset.</li>
                                        </ul>
                                </ol>
                            <dt>VI. NO UNLAWFUL OR PROHIBITED USE</dt>
                                <ol>
                                    <li>SERVER USAGE</li>
                                        <p>Corporate Technology expressly bans the use of any Semi-Dedicated server hardware or software for trying to illegally access another hosted machine or network. Customers may not attempt to gain unauthorized access to any of Corporate Technology's Services, other accounts, computer systems, or networks connected to any of Corporate Technology's Services, through hacking, password mining, or other means of close nature. Customers may not obtain or attempt to obtain any materials or information through any means not intentionally made available by Corporate Technology's Services. For more detailed information regarding all prohibited uses of our server network, please closely review the next chapter of these Terms and our Acceptable Use Policy (AUP). If you still have inquiries over the matter, feel free to contact any of our Customer Care representatives.</p>
                                    <li>HOSTED CONTENT</li>
                                        <p>Server content MUST comply with the parameters stated in this TOS and our Acceptable Use Policy. Web content requirements exclude the publication of illegal content or content considered inappropriate otherwise. If any of Corporate Technology's Services are used for the illegal purpose of SPAM or UCE, they will be instantly terminated. Customers are solely responsible for the content of their messages. Using Corporate Technology's Services in connection with surveys, contests, pyramid schemes, chain letters, junk e-mail, spamming or any duplicative or unsolicited messages (commercial or other) is prohibited. In this light, Corporate Technology expressly forbids any breach of Federal, State or Local regulation, including, but not limited to, the transmission of copyrighted material, material legally judged to be threatening or obscene, and material protected by trade secrets. Corporate Technology's Semi-Dedicated servers CANNOT be used for the propagation, distribution, housing, processing, storing, or handling in any way of a lewd, obscene, adult, or pornographic material, or any other material which is considered impermissible by Corporate Technology's Semi-Dedicated Terms of Service (this document) and the Acceptable Use Policy (AUP).</p>
                                    <li>CUSTOMER AGREEMENT</li>
                                        <p>As a condition for Customer use of Corporate Technology's Services, you - the Customer, agree that you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices, and our Acceptable Use Policy (AUP). Corporate Technology's Semi-Dedicated servers can be used explicitly and only for legal purposes, and each attempt for taking indecent advantage of the servers, inconsistent with the rules, will be immediately counteracted. Corporate Technology reserves the right at any time to disclose any information, as Corporate Technology deems necessary, to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post, or to remove from the Services any information or materials, in whole or in part, in the sole discretion of Corporate Technology.</p>
                                </ol>
                            <dt>VII. E-COMMERCE CUSTOMER WEBSITES</dt>
                                <dd>When buying a Semi-Dedicated server to create an e-commerce online store or related e-commerce activities website, the Customer agrees to be fully responsible for the development, operation, and maintenance of his/her web site/online store and e-commerce activities within that website. The Customer is the only one in charge of approving, processing, and filling client orders and taking care of client requests or complaints. The Customer is also the only one in charge of the payment or satisfaction of all taxes related to his/her web site and online store. The Customer bears the responsibility for ensuring the security and confidentiality of any clients' data (including, without limitation, customer credit card numbers) that he/she may receive in connection with his/her web site or online store.</dd>
                                <dd>Each Customer is in charge of all products and services, as well as all contents and materials appearing online, including:</dd>
                                    <ul>
                                        <li>the accuracy and relevance of Customer Content, and content and material showcased in the Customer store or on its products;</li>
                                        <li>The certainty that the Customer Content and content and materials appearing in the Customer store or on its products do not violate or infringe upon the rights of any person;</li>
                                        <li>The certainty that the Customer Content and the content and materials appearing in the Customer store or on its products are not calumnious or somehow illegal.</li>
                                        <li>Each Customer guarantees to Corporate Technology that he/she owns the right to use the Customer Content and its material, including all text, graphics, sound, music, video, programming, scripts, and applets. Each Customer warrants to Corporate Technology that the use, reproduction, propagation, and transmission of the Customer Content and any information and materials contained inside do not, and will not:</li>
                                        <li>infringe or abuse any copyright, patent, trademark, trade secret or any other proprietary right of a third party;</li>
                                        <li>break any criminal law or manifest false advertising, unfair competition, vilification, privacy intrusion; violate a right of publicity or violate any other law or regulation.</li>
                                        <li>Each Customer empowers Corporate Technology to reproduce, copy, use and propagate all and any part of the Customer Content for ensuring and operating the Services. Corporate Technology is granted the right to estimate, at its discretion, the extent to which these operations may be carried out.</li>
                                    </ul>
                            <dd>VIII. REFUSAL OF SERVICE</dd>
                                <ol>
                                    <li>SERVICE DENIAL</li>
                                        <p>Corporate Technology will thoroughly scrutinize all received orders and decide, to its discretion, which of them do not comply with the officially stated requirements for use of Corporate Technology's Semi-Dedicated servers. Corporate Technology reserves the right to refuse Service to unlawful parties.</p>
                                    <li>SERVICE DISCONTINUANCE</li>
                                        <p>Corporate Technology reserves the right to discontinue the provision of the Service (or any part thereof) to any subscriber that has violated these Terms, as well as remove and discard any Content within the Service, that Corporate Technology deems, at its sole discretion, has violated any condition of the Service explicated in these Terms of Services and/or the Acceptable Use Policy.</p>
                                </ol>
                            <dd>IX. MISCELLANEOUS</dd>
                                <ol>
                                    <li>NEWS RELEASES</li>
                                        <p>Certain sections of Corporate Technology's news releases may contain forward-looking statements projecting future events, such as new software incorporations, updates, promotions, hosting introductions, etc. These statements may deviate from the actual circumstances since they are treated as intentions and express expectations and approximate plans of action regarding the relevant forthcoming events.</p>
                                        <p>Forward-Looking Statements can be recognized by the availability of indicative words such as "believes", "anticipates," "plans," "may," "hopes," "can," "will," "expects," "is designed to," "with the intent," "potential", etc. However, their availability is not a prerequisite for a forward-looking statement to be treated as such.</p>
                                    <li>COMPLAINTS PROCEDURE</li>
                                        <p>Corporate Technology guarantees the highest level of Customer Care service round the clock. If you feel dissatisfied with any aspect of the Service, feel free to make use of our complaints procedure detailed below.</p>
                                        <p>To file a complaint, please send us an email to complaints@duoservers.com, specifying the nature of the problem you are experiencing. Please, provide as many details about your particular case as possible. The more we know about the situation, the better, prompt, and adequate our resolution will be.</p>
                                        <p>Here are our steps of addressing every single complaint:</p>
                                        <ul>
                                            <li>Corporate Technology will confirm receipt of your complaint in writing, confirming that the complaint will be addressed without delay. Corporate Technology reserves the right to refuse to investigate a complaint that is frivolous, abusive, or offensive.</li>
                                            <li>Corporate Technology's Customer Care representative will review your situation, look for an appropriate solution, and inform you of the timeframe within which a response should be expected.</li>
                                            <li>If Corporate Technology's Customer Care representative can't resolve your complaint within the pre-announced period, your complaint will be escalated to a Customer Care manager.</li>
                                            <li>If the Customer Care manager can't resolve your complaint, as well, it will be forwarded to the Company Director, who will investigate the case to ensure that all necessary measures have been taken and will respond in writing within two working days, and reply with a solution within five working days.</li>
                                        </ul>
                                    <li>TORTUROUS CONDUCT</li>
                                        <p>Any way of indecent treatment towards a Corporate Technology's Customer Care employee will be considered as totally inappropriate and unacceptable. Each Customer is expected to communicate in an utterly polite and professional manner with Corporate Technology's employees regarding any request for help or consultation via email, chat, phone, or through our Ticket Desk Support System. Each Customer is expected to avoid any form of emotional outburst or inappropriate language, or any other attempt to be undermined the importance of good ethics in communication and human relations. Any act of close nature, or other types of offensive, abusive, disrespectful, or condescending behavior - oral, written, or delivered by a second party - to members of Corporate Technology's Customer Care stuff will be interpreted as a breach of this TOS. Corporate Technology reserves the right to assess at its sole discretion which behavior is to be considered as a violation of the hereby stated rule and agreement and to undertake according actions.</p>
                                    <li>CUSTOMER SERVICE CONTACT DETAILS</li>
                                        <p>Customers can get in touch with our Customer Care representatives via any of the options listed below.
                                            <br></br>Technical Support
                                            <br></br>Via the ticketing systems - open a trouble ticket from the Web Hosting Control Panel (section Help Center - > Open New Ticket). 1-hour support response is guaranteed! (anchor to 1-hour Support Response Guarantee from Service Level Guarantees popup)
                                            <br></br>Pre-sale and general questions
                                        </p>
                                            <ul>
                                                <li>Phone us at: <a href="tel:7144412900"> US Phone +1 (714) 441-2900</a></li>
                                                <li>Use our Live Chat</li>
                                                <li>Send us your details:  <a href="https://www.corporatetech.com/contact-us">Contact Us</a></li>
                                            </ul>
                                </ol>
                            <dd>X. GENERAL REGULATIONS</dd>
                                <ol>
                                    <li>GOVERNING LAW</li>
                                        <p>This Agreement shall be governed by and construed in accordance with the laws of the State of Florida, US.</p>
                                    <li>ARBITRATION</li>
                                        <p>Any controversy or claim arising out of or relating to this Agreement, or any breach of this Agreement, in excess of 250.00 USD, will be settled in accordance with the commercial arbitration rules of the American Arbitration Association.</p>
                                    <li>ENFORCEMENT</li>
                                        <p>Failure to enforce any of the provisions of this Agreement shall in no way be considered to be a waiver of these provisions or to affect in any way the validity of this Agreement. If one or more of the provisions contained in this Agreement are found to be invalid, illegal, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions shall remain in full force and effect.</p>
                                    <li>INDEMNIFICATION</li>
                                        <p>The Customer agrees to indemnify Corporate Technology, its management body, and employees (each an "Indemnified Party") against any losses, claims, damages, liabilities, penalties, court actions, proceedings, or judgments (collectively, "Losses") to which the Indemnified Party may become subject, and out of which Losses arise or relate to this Agreement or the Customer's use of the Services. The Customer agrees to reimburse the Indemnified Party for all legal and other expenses, including any attorney fees paid by the Indemnified Party in connection with investigating, defending, or settling any Loss whether or not in connection with pending or threatened litigation in which the Indemnified Party is a party.</p>
                                    <li>LIMITATION ON Corporate Technology's LIABILITY</li>
                                        <p>Force Majeure Circumstances - Corporate Technology shall not be liable for any failure in the provision of the Services to the Customer resulting, directly or indirectly, from any (i) weather conditions and natural disasters, (ii) actions of any governmental or military authority, (iii) failure caused by telecommunications or Internet provider, or (iv)other force or occurrence beyond its control.</p>
                                        <p>Damages & Losses - Corporate Technology shall not be liable for (i) any indirect, incidental, special or consequential damages, OR for any loss of profits or loss of revenues resulting from the use of Corporate Technology's Services by the Customer or any third parties, or (ii) any loss of data resulting from delays, non-deliveries or service interruptions.</p>
                                        <p>Service Provision - Corporate Technology provides the Services on an "AS IS" basis, without any warranties, including but not limited to, implied warranties, merchantability, or fitness of the Services for any particular purpose. The Customer shall be solely responsible for the selection, use, and suitability of the Services, and Corporate Technology shall have no liability therefore.</p>
                                    <li>ASSIGNMENT</li>
                                        <p>Customers do not have the right to assign this Agreement without the explicit permission of Corporate Technology. This Agreement shall be in force and effect to the total benefit of the Customer and Corporate Technology and their successors and permitted assigns.</p>
                                    <li>REVISIONS</li>
                                        <p>Corporate Technology reserves the right to revise this document at any time, without prior notice. Customers are encouraged therefore to periodically review these Semi-Dedicated Terms of Service and the Acceptable Use Policy.</p>
                                </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
