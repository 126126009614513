import React from 'react'



function HeroManufacturing(props) {

    return (
        
        <div className="show-on-scroll inline-section">
            <div className="manufacturing__bg ">
                <div className="header__wrapper">
                <div className="header__title fromLeft">{props.heroTitle}</div>
                <div className="header__title--large fromLeft">{props.heroTitle2}</div>
                </div>
            </div>
        </div>
    )

}

export default HeroManufacturing