import React, { Component } from 'react'



export default class DS_DedicatedServiceGuarantee extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Dedicated Servers Service Level Guarantees</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Technology Dedicated Servers Service Level Guarantees</h1>
                    <div className="container-divider">
                        <ul> 
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>SERVICE GUARANTEES</dt>
                                <dd className="sub_detail_title">99.9% Network Uptime Guarantee</dd>
                                <dd>Corporate Technology guarantees that the server network where your dedicated server is located will be up and running at least 99.9% of the time during your subscription with us. However, we cannot be held responsible for upstream problems outside of our network and areas where Corporate Technology has no direct influence, such as backbone provider failures, fiber-optic main line cuts, DNS or Registrar problems with subscribers’ domain names, routing problems between a client’s location and our data center. The uptime guarantee is also not applicable if the service interruption was caused by a natural or unnatural event that is not directly under the control (or jurisdiction) of Corporate Technology. Corporate Technology may temporarily deny or terminate the Service upon client’s failure to pay their charges at due time.</dd>
                                <dd>Corporate Technology guarantees 99.9% network uptime and server stability for the server network where your dedicated server is located. This, however, does not refer to problems stemming from:</dd>
                                    <ol className="abcd_ordered_list" type="a">
                                        <li>Server Hardware Breakdown.</li>
                                        <li>User error(s) or purposeful interruption(s) (e.g. if the client shuts his/her server down, Corporate Technology is not responsible for the downtime).</li>
                                        <li>Failures due to software that is not explicitly supported by Corporate Technology. Consequently, if a hardware crash provoked by the customer happens, Corporate Technology has no responsibility for the resulting downtime.</li>
                                    </ol>

                                <dd><b>Network Outages or Unexpected Downtime </b>is any unplanned or unexpected interruption in the network availability due to hardware, software, network connectivity, or data center problem, during which a 100% packet loss is experienced.</dd>
                                <dd><b>Scheduled Downtime </b>is any scheduled interruption of the services for network software/hardware upgrades or replacement of any network equipment to be provided better service for the customers. Scheduled downtimes occur within pre-notified downtime periods, with as many warnings as possible sent via e-mail or posted in our news section minimum 24-hour in advance.</dd>

                            <dt>SUPPORT RESPONSE GUARANTEE</dt>
                                <dd>Corporate Technology guarantees 24/7 support available 365 days per year, for all the pre-installed software on your dedicated server. Support is available via email and the Dedicated Plan Manager integrated ticketing system. Each ticket is replied to within no more than 60 minutes after it has been opened.</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
