import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import MeetTheTeam from './MeetTheTeamCard';
import { meetTheTeam } from '../common/data';

const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    margin: 20,
    responsive: {
        0: {
            items: 1,
        },
        500: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        800: {
            items: 3,
        },
        900: {
            items: 5,
        },
        1000: {
            items: 5,

        },
        1366: {
            items: 6,
        }
    },
};

function MeetTheTeamCarousel() {
    return(
        <div className="dialog dialog-transparent">
            <h1 className="dialog_title">MEET SOME OF OUR TEAM</h1>
            <OwlCarousel className="owl-theme team_carousel_wrapper"
                {...options}>
                {
                    meetTheTeam.map(t => (
                        <MeetTheTeam 
                        image={t.image}
                        name={t.name}
                        position={t.position}
                        />
                    ))
                }
            </OwlCarousel>
        </div>
    )
}


export default MeetTheTeamCarousel