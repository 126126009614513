import React from 'react';

function Banner() {
  return (
    <div className='banner'>
        <p>Watch Veriato & CorporateTech "What Do Your Remote Employees Do All Day?" Webinar - Wednesday, January 27th. &nbsp;
            <span>
                <a href="https://cybersecurity.veriato.com/partnering-for-success-corp-tech">Click here to register.</a>
            </span>
        </p>
    </div>
  );
}
export default Banner
